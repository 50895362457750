import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';

@Component({
  selector: 'app-envolvente',
  templateUrl: './envolvente.component.html',
  styleUrls: ['./envolvente.component.css']
})
export class EnvolventeComponent implements OnInit {
  @Input() titulo="";
  @Input() magnitud="Magnitud";

  constructor() { }

  @Input() lineChartData: any[];
  @Input() specfrecs: any[];
  @Input() lineChartLabels: any[]; 
  // = ['0.1', '1', '5', '10', '20', '40', '80'];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {

    elements: 
    { 
        point: 
        {
            radius: 1,
            hitRadius: 5,
            hoverRadius: 10,
            hoverBorderWidth: 2
        }
    },
   
    responsive: true,
    title: {
      text: this.titulo,
      display: true
    },
    legend:
    {
      labels:{ 
        usePointStyle: true,
      }
      
    },
    showLines: true,
    scales: {
      //xAxes: [{scaleLabel: {display: true, labelString: 'Rango (umbral=1)'}}], yAxes: [{scaleLabel: {display: true, labelString: '%'}}] },
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
       // display: false,
       //type: 'logarithmic',
       //interval: 10,       
       //type: 'logarithmic',
      //position: 'left',
      scaleLabel: {
        labelString: 'Frecuencia (Hz)',
        display: true,
        
      },
      ticks: {
        //     fontColor: 'red',
        min:0,
        callback: function(value:number, index, values) {
          if ([0,10,20,30,40,50,60,70,80,90,100,120,140,160,180].includes(value))
          {
            return value
          }
            
          return "";
      }
           },
      type: 'linear',
      }],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          scaleLabel: {display: true, labelString: 'Magnitud [mg]'}
        },
        
        
        // {
        //   id: 'y-axis-1',
        //   position: 'right',
        //   gridLines: {
        //     color: 'rgba(120,120,120,0.3)',
        //   },
        //   ticks: {
        //     fontColor: 'red',
        //   }
        // }
      ]
    }, 
    annotation: {
      annotations: [
        {
          type: 'spline',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };





  public lineChartColors: Color[] = [

  ];
  public lineChartLegend = true;
  public lineChartType = 'line';




   ngOnInit() {
    this.lineChartOptions = {


      elements: 
      { 
          point: 
          {
              radius: 5,
            
              
          }
      },
        title: {
      text: this.titulo, 
      display: true
    },
    responsive: true,
    legend:
    {
      display:false,
      labels:{ 

      }
      
    },
    showLines: true,
    scales: {

      xAxes: [{

      scaleLabel: {
        labelString: 'Tiempo (s)',
        display: true,
        
      },
      ticks: {

        min:0,
        callback: function(value:number, index, values) {
          if ([0,10,20,30,40,50,60,70,80,90,100,120,140,160,180].includes(value))
          {
            return value
          }
            
          return "";
      }
           },
      type: 'linear',
      }],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          scaleLabel: {display: true, labelString: this.magnitud}
        },
        
      ]
    }, 
    annotation: {
      annotations: [
        {
          type: 'spline',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
     
    };

  }

  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
 

}
