import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label,Color } from 'ng2-charts';
import {Router, NavigationExtras} from '@angular/router';

@Component({
  selector: 'app-evento',
  templateUrl: './evento.component.html',
  styles: [
  ]
})
export class EventoComponent implements OnInit {

  bucket="";
  key=""
  databar={datachart:[[],[]],dataranges:[]};
  radarPGA=[{ data: [1.3, 1, 1, 1, 1, 1], label: 'Theorical' },
  { data: [1.25, 1.1, 1.2, 1, 1.5, 1.5], label: 'Data' }];
  labelRadarAccel= ['AX1', 'AY1', 'AZ1'];
  datareadyEvents=false;
  @Input() asset:any = {};
  loggedin:boolean;
  aws_accessKeyId:string;
  aws_secretAccessKey:string;
  aws_region:string;
  @Input() state;
  public Histograma:ChartDataSets[]=[{data:[],label:""}];
  public HRanges:any[];
  public data=window.location.href.split('/')[5];
  public pageprev=window.location.href.split('/')[4];
  public dataevento;
  public sensores=[{id:"A1-CH5",state:"activo"},{id:"A2-CH5",state:"mantencion"},
  {id:"A3-CH5",state:"errorgrave"},{id:"A4-CH5",state:"inactivo"}];

  constructor(private router:Router) { 
    console.log(this.data);
  }

  ngOnInit(): void {

    // let tempdata =this.serviceAWS.GetData("EventsSGS",
    //   "#yr , Lugar, Histograma, PGA, Tinit,Sensor,FileUrl,Espectros,Frecuencias_Naturales",
    //   "#yr = :ID",
    //   {"#yr": "ID"},
    //   {":ID": this.data}
    // ).then(res=>
    // {
    // });
   
    // console.log("DataTemp:");
    // console.log(tempdata);
    // this.dataevento=tempdata;
    // console.log("Data:");
    // console.log(this.data);

  }

  DownloadS3()
  {
    console.log(this.bucket,this.key);
    
  }
  NavigatePrev()
  {

    this.router.navigate(["Assets",this.pageprev]);
  }

}
