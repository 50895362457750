
    
    
    <div class="row">
      <div class="col">
        <button *ngIf="selectedIDminus"  (click)="next(-1)"  class="btn btn-secondary btn-block" style="width: auto;" >
          {{sensores[selectedID-1].slice(0,5)}}</button>
      </div>
      <div class="col">
        <button (click)="next(0)"  class="btn btn-primary btn-block" style="width: auto;" >
          {{sensores[selectedID].slice(0,5)}}</button>
      </div>
      <div class="col">
        <button *ngIf="selectedIDplus"(click)="next(1)"  class="btn btn-secondary btn-block" style="width: auto;" >
          {{sensores[selectedID+1].slice(0,5)}}</button>
      </div>
    </div>
        
        
          




  

<!-- <div class="col" style="max-width: 70px;" *ngFor="let item of database.datachart[0]; let i = index" >
<button  (click)="next(i)"  class="btn btn-outline-secondary btn-block" style="width: 70px;" ><small>{{sensores[i]}}</small></button></div> -->