
<div class="center" style="text-align:center">
    
        <span class="badge badge-pill badge-light"  style="min-width: 300px; ">
            <div class="row">
                <!-- Blue -->
                <div id="SemaforoActiveOn" class="col" width="25%"  *ngIf="estructura.state == 'Active1'" > 
                    <h5><span class="badge badge-pill badge-primary animated blob pulse"> <i class="fa fa-circle"   title="Conectado, sin Información"></i></span></h5></div>
                <div id="SemaforoActiveOff" class="col" width="25%" *ngIf="estructura.state != 'Active1'">
                    <h5><span style="opacity: 0.3;"  class="badge badge-pill badge-primary "> <i class="fa fa-circle-notch"   title="Conectado, sin Información"></i></span></h5></div>
                 <!-- Green -->
                <div id="SemaforoMinorOn" class="col" width="25%" *ngIf="estructura.state != 'Error'">
                    <h5><span  class="badge badge-pill badge-success animated blob pulse"><i   class="fa fa-circle" title="Activo Operando Con Normalidad"></i> </span></h5></div>
               <div id="SemaforoMinorOff"class="col" width="25%" *ngIf="estructura.state == 'Error'" >
                    <h5><span style="opacity: 0.3;" class="badge badge-pill badge-success"><i class="fa fa-circle-notch"   title="Activo Operando con Normalidad"></i></span></h5></div> 
    
                <!-- Yellow -->
                <div id="SemaforoMAintenanceOn" class="col" width="25%" *ngIf="estructura.state == 'Maintenance1'">
                    <h5><span class="badge badge-pill badge-warning animated blob pulse"><i class="fa fa-circle"   title="Activo en 80% de Capacidad Definida"></i> </span></h5></div>
                <div id="SemaforoMaintenanceOff" class="col" width="25%" *ngIf="estructura.state != 'Maintenance1'">
                    <h5><span style="opacity: 0.3;" class="badge badge-pill badge-warning"><i class="fa fa-circle-notch"   title="Activo en 80% de Capacidad Definida"></i></span></h5></div>
                
                 <!-- RED -->
                <div id="SemaforoDangerOn" class="col" width="25%" *ngIf="estructura.state == 'Severe1'" >
                    <h5><span  class="badge badge-pill badge-danger animated blob pulse"><i class="fa fa-circle"   title="Activo en Estado Crítico"></i></span></h5></div>
                <div id="SemaforoDangerOff" class="col" width="25%" *ngIf="estructura.state != 'Severe1'" >
                    <h5><span style="opacity: 0.3;"  class="badge badge-pill badge-danger"><i class="fa fa-circle-notch"   title="Activo en Estado Crítico"></i></span></h5></div>
            </div>            
        </span>
        <span class="badge badge-pill badge-light"  style="min-width: 100px; ">
            <div class="row">
                <!-- Blue -->
                <div class="col" width="25%"  *ngIf="estructura.state != 'Error'" > 
                    <h5><span class="badge badge-pill badge-primary animated blob pulse"> <i class="fas fa-wifi"   title="Conexión Activa"></i></span></h5></div>
                <div class="col" width="25%" *ngIf="estructura.state == 'Error'">
                    <h5><span style="opacity: 0.3;"  class="badge badge-pill badge-secondary "> <i class="fas fa-wifi"   title="Conexión Inactiva"></i></span></h5></div>
                
    
                
            </div>            
        </span>
        
    
</div>
