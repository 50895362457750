<div class="container" fxLayout="row" fxLayoutAlign="center none">

  <div fxFlex="95%" fxLayout="column">

    <h1 style="color: #469aa5">Seleccionar cámara</h1>

    <div fxFlex="100">
      <ul role="list">
        <li role="listitem" class="listItem" *ngFor="let device of devices;">
          <button (click)="goTo()">{{device.name}}</button>
          <!-- <a [routerLink]="[device.id]">{{device.name}}</a> -->
        </li>
      </ul>
    </div>

  </div>
</div>