<nav class="navbar navbar-expand-lg navbar justify-content-between"  style="overflow-x: auto; background-color: rgb(255, 255, 255); vertical-align: middle;">
            <a class="navbar-brand" href="" style=" background-color: white; zoom: 10%"> 
                <!-- border:5px solid rgb(255, 92, 17); border-radius: 5%; -->
                <img  src="assets/images/Logobhp-01.png"  alt="">
            </a>
            <p  class="navbar-brand" style=" color:rgb(236, 95, 1); font-size: auto; font-weight: bold; zoom:80% ; align-content: center;">
                SISTEMA DE MONITOREO REMOTO | <small>MINERA ESCONDIDA </small>
            <small *ngIf="asset" style=" color:rgb(110, 110, 110); font-size: auto; " style="text-align: center;"> 
                 | {{asset.nombre}}
            </small>
            <small *ngIf="selected" style=" color:gb(110, 110, 110); font-size: auto; " style="text-align: center;"> 
                | {{selected.Tinit | date:'dd MMM yyyy HH:mm:ss'}}
           </small>
            <br/>
            GERENCIA DE INGENIERÍA INTEGRADA - SUPERINTENDENCIA ASSET INTEGRITY <br/>
            </p>

            <br>
            <app-semaforoactivo  *ngIf="estructura" [estructura]="estructura" style="border: 1px solid rgb(143, 143, 143);border-radius: 10px ;"></app-semaforoactivo>
           
            <br>
            <button  (click)="cerrarSesion()" style="min-width: 300; max-height: 100px;  background-color: rgb(110, 110, 110)" class="btn btn-secondary my-2 my-sm-0"> <i class="fas fa-power-off"></i> Cerrar Sesión</button>
</nav>
<!-- 

<nav class="navbar navbar-expand-lg navbar-dark bg-dark justify-content-between"  >
    <div class="row">
        <div class="span2" >
            <a class="navbar-brand" href="Assets" >
                <img src="assets/images/SGSLOGO.png" width="180px" height="100px" alt="">
            </a>
        </div> 
        <div class="span2" >    
            <p  class="navbar-brand" style=" color:white; font-size: auto; ">
                SISTEMA DE MONITOREO REMOTO DE ACTIVOS CRITICOS <br> <small>MINERA ESCONDIDA</small>
            <br>
            <span *ngIf="asset" class="navbar-brand" style=" color:white; font-size: auto; " style="text-align: center;"> 
                {{asset.nombre}}
            </span>
            </p>
        </div>
        <div class="span2" style="min-width: 400px; text-align: center;" >
            <br>
            <app-semaforoactivo  *ngIf="estructura" [estructura]="estructura"></app-semaforoactivo>
            <br>
            <button  (click)="cerrarSesion()" style="width: 100; max-height: 50px;" class="btn btn-secondary my-2 my-sm-0"> <i class="fas fa-power-off"></i> Cerrar Sesión</button>
        </div>
      
    </div>
</nav> -->