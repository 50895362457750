import { Component, OnInit } from '@angular/core';

import { ActivatedRoute ,Router} from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  assets:any[]=[];

  constructor(private activatedRoute:ActivatedRoute,
              private navserv:Router
    ) { 

      // this._assetService.getProperties().then(res=>
      //   {

      //     console.log("GetProp",res);
      //     this._assetService.assets=res.Items;
      //     this.activatedRoute.params.subscribe(params=>
      //       {
      
      //         console.log("Termino",params['termino']);
      //         this.assets=this._assetService.searchAssets(params['termino']);
      //         console.log("Assets",this.assets);
      //       });


      //   });





    }

  ngOnInit(): void {

    
  }
  Navigate(idx:number)
  {
    this.navserv.navigate(["Assets",idx]);

  }

}
