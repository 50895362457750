<div style="max-height: 30vh; margin: 0%;">
    <table class="table" >
        <thead>
            <th >Fecha</th>
            <th >Alerta</th>   
            <th>Causa</th>   
            <th ></th>

        </thead>
        <tbody > 
            <tr *ngFor="let item of events; let i = index" style="max-height: 25px; margin: 0%;">
                <td  #number>{{item.Tinit | date:'dd MMM yyyy HH:mm:ss'}}</td> 
                <td *ngIf="item.EventoCausa.split('|')[1]=='Check'"  #idnumber><i class="fa fa-circle"></i></td> 
                <td *ngIf="item.EventoCausa.split('|')[1]=='Rojo'"  #idnumber><i class="fa fa-circle" style="color: green;"></i></td> 
                <td *ngIf="item.EventoCausa.split('|')[1]=='Amarillo'"  #idnumber><i class="fa fa-circle" style="color: green;"></i></td> 
                <td *ngIf="item.EventoCausa.split('|')[1]=='Verde'"  #idnumber><i class="fa fa-circle" style="color: green;"></i></td> 
                <td *ngIf="item.EventoCausa.split('|')[1]=='Azul'"  #idnumber><i class="fa fa-circle" style="color: blue"></i></td> 
                <td>{{item.EventoCausa.split('|')[0]}}</td>   
                <td > <button 
                class="btn btn-outline-primary my-2 my-sm-0" type="button" (click)="Ir(item.ID)" style="max-height: 35px; margin: 0%;"   >Ir</button>
                    
                    <!-- <button (click)="DownloadEvent(number.innerHTML)" class="btn btn-primary my-2 my-sm-0" type="button"><i class="fas fa-download"></i></button> -->
                </td>
            </tr>
    </tbody>
  </table>
</div>
      
