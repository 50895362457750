import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

//import {DynamoDB} from  'aws-sdk';
//Graficos
import{ChartsModule} from 'ng2-charts';

//Rutas
import { APP_ROUTING } from './app.routes';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';

// Componentes
import { LoginComponent } from './login';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
// import { HomeComponent } from './components/home/home.component';
import { ActivosComponent } from './components/activos/activos.component';
import { SectoresComponent } from './components/sectores/sectores.component';
import { SensoresComponent } from './components/sensores/sensores.component';
import { SearchComponent } from './components/search/search.component';
import { SearchCardComponent } from './components/search-card/search-card.component';
import { ChartlineComponent } from './components/shared/chartline/chartline.component';
import { ChartbarComponent } from './components/shared/chartbar/chartbar.component';
import { RadarbarComponent } from './components/shared/radarbar/radarbar.component';
import { SpeclineComponent } from './components/shared/specline/specline.component';
import { DataTableComponent } from './components/shared/data-table/data-table.component';
import { SensorTableComponent } from './components/shared/sensor-table/sensor-table.component';
import { DropdownComponent } from './components/shared/dropdown/dropdown.component';
import { EventoComponent } from './components/shared/evento/evento.component';
import { SensorpageComponent } from './components/shared/sensorpage/sensorpage.component';
import { SensorformComponent } from './components/shared/sensorform/sensorform.component';
import { SemaforoComponent } from './components/shared/semaforo/semaforo.component';
import { SemaforoactivoComponent } from './components/shared/semaforoactivo/semaforoactivo.component';
import { ClickimgComponent } from './components/clickimg/clickimg.component';
import { Dropdown2Component } from './components/dropdown2/dropdown2.component';
import { RMComponent } from './components/rm/rm.component';
import { MapimgComponent } from './components/mapimg/mapimg.component';
import { VideocamComponent } from './components/videocam/videocam.component';
import { RmimgComponent } from './components/rmimg/rmimg.component';
import { GraphimgComponent } from './components/graphimg/graphimg.component';
import { HistoEventsComponent } from './components/histo-events/histo-events.component';
import { EnvolventeComponent } from './components/envolvente/envolvente.component';
import { MapadinamicoComponent } from './components/mapadinamico/mapadinamico.component';
import { CamaravideoactivaComponent } from './components/camaravideoactiva/camaravideoactiva.component';
import { CamaraliveComponent } from './components/camaralive/camaralive.component';


@NgModule({
  declarations: [
    LoginComponent,
    AppComponent,
    NavbarComponent,
    // HomeComponent,
    ActivosComponent,
    SectoresComponent,
    SensoresComponent,
    SearchComponent,
    SearchCardComponent,
    ChartlineComponent,
    ChartbarComponent,
    RadarbarComponent,
    SpeclineComponent,
    DataTableComponent,
    SensorTableComponent,
    DropdownComponent,
    EventoComponent,
    SensorpageComponent,
    SensorformComponent,
    SemaforoComponent,
    SemaforoactivoComponent,
    ClickimgComponent,
    Dropdown2Component,
    RMComponent,
    MapimgComponent,
    VideocamComponent,
    RmimgComponent,
    GraphimgComponent,
    HistoEventsComponent,
    EnvolventeComponent,
    MapadinamicoComponent,
    CamaravideoactivaComponent,
    CamaraliveComponent,
    
    

  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    APP_ROUTING,
    ChartsModule,
    //DynamoDB

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
