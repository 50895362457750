import { Component, OnInit ,Input, Output,EventEmitter} from '@angular/core';
import {Router, NavigationExtras, OutletContext} from '@angular/router';


@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styles: [
  ]
})

export class DataTableComponent implements OnInit {

  @Input() events;
  @Input() selected;
  @Input() activo;
 @Output() eventclicked:EventEmitter<any>=new EventEmitter<any>();
   @Output() downloadclicked:EventEmitter<any>=new EventEmitter<any>();
  //public selected="1";
  constructor(private router:Router) { }

  ngOnInit(): void {
    
    }
  
    Ir(ID)
    {
      console.log("Evento selected",this.selected)
      this.router.navigate(["Event",this.activo,ID]);

    }
  buscarEvent(value,number)
  {
  //   console.log(value);
  //   let navigationExtras: NavigationExtras = {
  //     queryParams: {
  //         "state": this.data_Events, 
  //         "data": this.page
  //     }
  // };
    // document.cookie="";
  
      this.eventclicked.emit(number);
       this.selected=value;
    
    //this.router.navigate(["Assets",this.page,value]);

  }
  DownloadEvent(value)
  {
    console.log("Downloading",value);
    //this.downloadclicked.emit(value);
  }

}
