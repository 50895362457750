import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-activos',
  templateUrl: './activos.component.html'
})
export class ActivosComponent implements OnInit {

  assets=[];
  estructura=[];
  winwidth=window.innerWidth
  connected=0
  Total=0
  eventos=[]
  activo=""
  databar=[
    { data: [0, 0, 0, 0, 0], label: 'Eventos Semanales' }
  ];
  ranges=['1', '2', '3', '4', '5'];
  Loaded=false
  LoadedA=false

  Ir(activo,ID)
  {
    var acttemp=activo
    activo='{"nombre":"'+activo+'","acelerometros":"2","straing":"1","Fecha":"Mar 2020","clave":"'+activo+'","img":"cv239.jpg","distanciometer":"1","current":"0","coords":[["473,110,11","ACC1"],["610,110,11","ACC2"],["610,131,11","STG1"],["610,87,11","DIS1"]],"state":"Active","Wn":[]}'
    localStorage.setItem('asset',activo)
    this.navserv.navigate(["Event",acttemp,ID]);
  }

  constructor(
              // private _assetsService:AssetsService,
              private navserv:Router,
              // private serviceAWS:AwsService,
              private http: HttpClient
              ) {

this.GetEvents()
                
               }

  ngOnInit(): void {

    var self = this
    this.http.get<any[]>(`${environment.apiUrl}/api/activos`).subscribe(data => { 
      
      console.log("Assets",data)
      self.assets = data
      
      for (var i = 0; i < data.length; i++) {


this.Total+=1
if(data[i]['state']!='Error'){

this.connected+=1
}
//console.log("Connected:",this.connected,"/",this.Total)

      }
    

    
    
    });
//     return
//     this._assetsService.getProperties().then(res=>
//       {

            
//           var temp=[];
//           res.Items.forEach((element,index) => {
//               temp.push({nombre:element.NameA, acelerometros:element.Accels,straing:element.Strain,Fecha:element.dateinit
//                   ,clave:element.Id,img:element.ImgUrl,idx:index,distanciometer: element.Distance, current:element.CurrentS});
//               this.estructura.push({state:element.StateS});


              
//             //#region 
//             //   let tempdata3 =this.serviceAWS.GetData("Sensores",
//             //   "#yr , Id, StateS, TypeS",
//             //   "#yr = :Structure",
//             //   {"#yr": "Structure"},
//             //   {":Structure": temp[index].clave}
//             // ).then(res=>
//             //   {
//             //     console.log("Sensores: ",res);
//             //     var sensores=res.Items;
//             //     var boolok=true;
//             //     sensores.forEach(element=>{
//             //       if(element.StateS=="Error")
//             //           {
//             //             this.estructura[index].stateErr="Error";
//             //             boolok=false;
//             //           }
//             //       else if(element.StateS=="Manteinance")
//             //           {
//             //             this.estructura[index].stateMant="Manteinance";
//             //             boolok=false;
//             //           } 
//             //       else if(element.StateS=="Inactive")
//             //           {
//             //             this.estructura[index].stateInac="Inactive";
//             //             boolok=false;
//             //           }
    
//             //     })
//             //     if(boolok){
//             //       this.estructura[index].stateGood="Active";
//             //     }
//             //     console.log("Estructura",this.estructura[index]);
//             //   });
// //#endregion

//           });
//           console.log("Activos",temp); 
//           this.assets=temp;
// });
  this.SetEventsSemanal()
  this.Loaded=true
  
  }
  DownloadFileS3()
  {
    
    window.open('https://s3-us-west-2.amazonaws.com/roc.sgs.report/Informes/Summary.pdf', "_blank");


  }
  SetEventsSemanal()
  {
  
      setTimeout(() => { 
        
        try{
        this.databar=[{data:this.assets[0]["EventoSemanal"][0],label:"Eventos Semanales"}] 
        this.ranges=this.assets[0]["EventoSemanal"][1] 
        }
        catch{
          this.SetEventsSemanal()
        }
      }, 2000);
      
    
   
  }

  GetEvents(){
    var yourDate = new Date();  // for example
    var yourDate1 = new Date();  

    // the number of .net ticks at the unix epoch
    var epochTicks = 621355968000000000;

    // there are 10000 .net ticks per millisecond
    var ticksPerMillisecond = 10000;

    // calculate the total number of .net ticks for your date
    var yourTicks = (yourDate.getTime() * ticksPerMillisecond)/10000000-30*24*60*60;
    var yourTicks2 = (yourDate1.getTime() * ticksPerMillisecond)/10000000;
  
    //console.log(elementodp) 
    var clavetick=parseInt(yourTicks as any).toString()+"_"+parseInt(yourTicks2 as any).toString()//16261344000000000
    console.log("ClaveTick",clavetick)

    var clave=clavetick

    this.http.get(`${environment.apiUrl}/api/events3/${clave}`).subscribe(res => 
      {
        try{
        this.eventos = res["Items"]
        this.eventos.map(function(el){el["Tinit"] = new Date(el["Tinit"]*1000+60*60*4000)})
        console.log("Events",this.eventos)}
        catch(error){
          console.warn('Err',error);
         }
 
         
         


  
   
  


   })
  }


  // Navigate(idx:number)
  // {
  //   this.navserv.navigate(["Assets",idx]);

  // }
}

