import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment.prod';
import { MapCustomService } from '../../services/map-custom.service';


@Component({
  selector: 'app-mapadinamico',
  templateUrl: './mapadinamico.component.html',
  styleUrls: ['./mapadinamico.component.css']
})
export class MapadinamicoComponent implements OnInit {

  constructor(private MapCustomService:MapCustomService) { 
       
  }



ngOnInit(): void {

this.MapCustomService.buildMap().then((data)=>{
console.log('todo bien',data)

}).catch((err)=>{
console.log('ERROR',err)

})

}



}
