
      <div >
        <div class="row">
          <canvas baseChart
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [colors]="BarChartColors"
          [legend]="barChartLegend"
          [chartType]="barChartType"
          style="min-height: 300px; min-width: 400px;">
           
        </canvas>

        </div>

        <div class="row" style="margin-left: 25%;">
    
         
          <app-dropdown2 (eventclicked)="next($event)" [database]="database" [sensores]="sensores"></app-dropdown2>
          
          
          
        </div>
       
      </div>
      
      

