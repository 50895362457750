import { Component, OnInit, Attribute } from '@angular/core';
import {Router, NavigationExtras} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { ActivatedRoute} from '@angular/router';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
@Component({
  selector: 'app-sensorpage',
  templateUrl: './sensorpage.component.html',
  styles: [
  ]
})
export class SensorpageComponent implements OnInit {

  T1="Aceleraciones"
  T2="Deformaciones"
  M1="Magnitud [mg]"
  M2="Magnitud [ue]"
  envdata=[]
  envdata2=[]
  winwidth=window.innerWidth
  puntosenvacc=[]
  puntosenvsg=[]
  puntosenvdis=[]
  dataenv=[[],[],[],[]]
  public data:any=window.location.href.split('/')[6];//Length-1
  public datasubs=this.data.substring(0,2)
  public previous=window.location.href.split('/')[5];
  asset=""
  estructura=""
  fecha=""
  events=[]
  public sensores
  public state="Azul"
  public datasensor=[{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"Señal"}]
  public labelsensor=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
  public dataRadarMean=[]
  public dataRadarPeaks=[]
  public videolink=""
  labelRadarPeaks= [];
  labelRadarMean= [];
  rmurl=""
  public speclabel=[];
  public specdata=[];
  specfrecs=[]
  rmdata=[]
  rmfrecs=[]
  

  public Histograma:ChartDataSets[]=[{data:[],label:""}];
  public HRanges:any[];
  public databar:any = {datachart:[[]],datalabel:[]};
  histonchange=false;



  selected:any=0
  selectedId=0
  public LEK=[]
  public LEKidx=0
  public LastEvKey=""
  public dataS:any
  public initialize=false
  public hayeventos=false
  public cargando=true
  public unidades="mm"
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: false,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{

        ticks: {
          display: false //this will remove only the label
      },
      gridLines: {
        display: false
      }
      

      }],
      yAxes: [
        {
          
          id: 'y-axis-0',
          position: 'left',
          gridLines: {
            display: false
          },
          scaleLabel: {display: true, labelString: 'Magnitud'+this.unidades,fontSize: 30},
          ticks: {
            fontSize: 20
        }
        },
       
        
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'spline',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };

  





  constructor(private router:Router, private http: HttpClient,private activatedRoute:ActivatedRoute) {

    this.asset=JSON.parse(localStorage.getItem('asset'))
    this.estructura=JSON.parse(localStorage.getItem('estructura'))
    
    
    if (this.data.substring(0,2)!="")
    {
      this.unidades="cm/s^2"
      this.lineChartOptions.scales.yAxes[0].scaleLabel.labelString="Magnitud ["+this.unidades+"]"
      var consulta=this.data//this.data+
      console.log(consulta)
      this.http.get(`${environment.apiUrl}/api/eventC/${consulta}`).subscribe(res => 
        {
          console.log("Response",res)
         
        
         
         this.selected = res["Items"][0]   
         this.asset["nombre"]=this.asset["nombre"]
         console.log(this.estructura)
         this.events = res["Items"]
         this.videolink=res["Items"][0].RMurl.replace('RM_','video_').replace('.png','.mp4')
         console.log("Videolink",this.videolink)
         this.cargando=false
          var sens:any=res["Items"][0].Sensor
         this.sensores=[] 
         var contador=0;
         this.state=this.selected["EventoCausa"].split("|")[1]
         this.rmurl=this.selected["RMurl"]

         if(this.selected['Envolvente']){ 
           this.dataenv[0]=this.selected['Envolvente'][0]['AccMax']
           this.dataenv[1]=this.selected['Envolvente'][1]['AccMin']
           this.dataenv[2]=this.selected['Envolvente'][2]['SgMax']
           this.dataenv[3]=this.selected['Envolvente'][3]['SgMin']
          console.log("Envolvente",this.dataenv)
          }
          if(this.selected['PuntosEnv']){

this.puntosenvacc=this.selected['PuntosEnv'][0]
this.puntosenvsg=this.selected['PuntosEnv'][1]
this.puntosenvdis=this.selected['PuntosEnv'][2]

          }

         for (var i = 0; i < sens.length; i++) {
           if(sens[i].substring(0,2)=="AC"){
            this.sensores.push([contador,contador+1,contador+2])
            contador+=3
           }
           else
           {
            this.sensores.push([contador])
            contador+=1
           }
  
          
          }
          
          var index=sens.indexOf(this.data+"A")
          if(this.data.substring(0,2)!="ST")
          {
            index=sens.indexOf(this.data)
          }
          console.log(this.sensores,index)//,this.sensores[index][0]+1)
          
        //  if(this.data.substring(0,2)=="AC"){
  
        //   this.datasensor=[{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"Ax"},{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"Ay"},
        //   {data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"Az"}]
        //  this.datasensor[0].data=this.selected.DataReducida[this.sensores[index][0]+1].map(Number)//Falta Cambiar por correspondiente
        //  this.datasensor[1].data=this.selected.DataReducida[this.sensores[index][1]+1].map(Number)
        //  this.datasensor[2].data=this.selected.DataReducida[this.sensores[index][2]+1].map(Number)
        //  this.labelsensor=this.selected.DataReducida[0].map(Number)
  
        // }
        // else
        // {
        //   if(this.data.substring(0,2)=="ST")
        //   {
        //     this.datasensor=[{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"A"},{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"B"},
        //     {data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"C"}]
        //    this.datasensor[0].data=this.selected.DataReducida[this.sensores[index][0]+1].map(Number)//Falta Cambiar por correspondiente
        //    this.datasensor[1].data=this.selected.DataReducida[this.sensores[index+1][0]+1].map(Number)
        //    this.datasensor[2].data=this.selected.DataReducida[this.sensores[index+2][0]+1].map(Number)
        //    this.labelsensor=this.selected.DataReducida[0].map(Number)
        //   }
        //   else{
        //   this.datasensor[0].data=this.selected.DataReducida[this.sensores[index][0]+1].map(Number)
        //   this.labelsensor=this.selected.DataReducida[0].map(Number)
        //     }
        // }


         try {
         this.selectedId= this.selected["ID"]
         console.log("Selected",res["Items"])
        }     
        catch (error) {
          console.warn(error);
          
          // expected output: ReferenceError: nonExistentFunction is not defined
          // Note - error messages will vary depending on browser
        }

        this.fillRadarData(this.selected, this.events)
        try{
          this.populate()
          }
          catch(error){
            console.warn('Err',error);
          }
        this.cargando=false
      
        this.hayeventos=true
        //this.events.map(function(el){el["Tinit"] = new Date(el["Tinit"]*1000+60*60*3000)})
      })
    }

    // if (this.data.substring(0,2)=='DI')
    // {
    //   this.unidades="mm"
    //   this.lineChartOptions.scales.yAxes[0].scaleLabel.labelString="Magnitud ["+this.unidades+"]"
    //   var consulta=this.data+'_SGS_'+this.previous//this.data+
    //   console.log(consulta)
    //   this.http.get(`${environment.apiUrl}/api/events2/${consulta}`).subscribe(res => 
    //     {
    //       console.log("Response",res)
         
        
          
    //      this.selected = res["Items"][0]   
    //      this.events = res["Items"]
    //      this.cargando=false
    //       var sens:any=res["Items"][0].Sensor
    //      this.sensores=[] 
    //      var contador=0;
    //      for (var i = 0; i < sens.length; i++) {
    //        if(sens[i].substring(0,2)=="AC"){
    //         this.sensores.push([contador,contador+1,contador+2])
    //         contador+=3
    //        }
    //        else
    //        {
    //         this.sensores.push([contador])
    //         contador+=1
    //        }
  
          
    //       }
          
    //       var index=sens.indexOf(this.data+"A")
    //       if(this.data.substring(0,2)!="ST")
    //       {
    //         index=sens.indexOf(this.data)
    //       }
    //       console.log(this.sensores,index)//,this.sensores[index][0]+1)
          
    //      if(this.data.substring(0,2)=="AC"){
  
    //       this.datasensor=[{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"Ax"},{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"Ay"},
    //       {data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"Az"}]
    //      this.datasensor[0].data=this.selected.DataReducida[this.sensores[index][0]+1].map(Number)//Falta Cambiar por correspondiente
    //      this.datasensor[1].data=this.selected.DataReducida[this.sensores[index][1]+1].map(Number)
    //      this.datasensor[2].data=this.selected.DataReducida[this.sensores[index][2]+1].map(Number)
    //      this.labelsensor=this.selected.DataReducida[0].map(Number)
  
    //     }
    //     else
    //     {
    //       if(this.data.substring(0,2)=="ST")
    //       {
    //         this.datasensor=[{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"A"},{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"B"},
    //         {data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"C"}]
    //        this.datasensor[0].data=this.selected.DataReducida[this.sensores[index][0]+1].map(Number)//Falta Cambiar por correspondiente
    //        this.datasensor[1].data=this.selected.DataReducida[this.sensores[index+1][0]+1].map(Number)
    //        this.datasensor[2].data=this.selected.DataReducida[this.sensores[index+2][0]+1].map(Number)
    //        this.labelsensor=this.selected.DataReducida[0].map(Number)
    //       }
    //       else{
    //       this.datasensor[0].data=this.selected.DataReducida[this.sensores[index][0]+1].map(Number)
    //       this.labelsensor=this.selected.DataReducida[0].map(Number)
    //         }
    //     }
    //      try {
    //      this.selectedId= this.selected["ID"]
    //      console.log("Selected",res["Items"])
    //     }     
    //     catch (error) {
    //       console.warn(error);
          
    //       // expected output: ReferenceError: nonExistentFunction is not defined
    //       // Note - error messages will vary depending on browser
    //     }
    //     this.cargando=false
    //     this.hayeventos=true
    //     this.events.map(function(el){el["Tinit"] = new Date(el["Tinit"]*1000+60*60*3000)})
    //   })
    // }


    //  if (this.data.substring(0,2)=='ST')
    // {
    //   this.unidades="mm"
    //   this.lineChartOptions.scales.yAxes[0].scaleLabel.labelString="Magnitud ["+this.unidades+"]"
    //   var consulta=this.data+'A_SGS_'+this.previous//this.data+
    //   console.log(consulta)
    //   this.http.get(`${environment.apiUrl}/api/events2/${consulta}`).subscribe(res => 
    //     {
    //       console.log("Response",res)
         
        
          
    //      this.selected = res["Items"][0]   
    //      this.events = res["Items"]
    //      this.cargando=false
    //       var sens:any=res["Items"][0].Sensor
    //      this.sensores=[] 
    //      var contador=0;
    //      for (var i = 0; i < sens.length; i++) {
    //        if(sens[i].substring(0,2)=="AC"){
    //         this.sensores.push([contador,contador+1,contador+2])
    //         contador+=3
    //        }
    //        else
    //        {
    //         this.sensores.push([contador])
    //         contador+=1
    //        }
  
          
    //       }
          
    //       var index=sens.indexOf(this.data+"A")
    //       if(this.data.substring(0,2)!="ST")
    //       {
    //         index=sens.indexOf(this.data)
    //       }
    //       console.log(this.sensores,index)//,this.sensores[index][0]+1)
          
    //      if(this.data.substring(0,2)=="AC"){
  
    //       this.datasensor=[{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"Ax"},{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"Ay"},
    //       {data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"Az"}]
    //      this.datasensor[0].data=this.selected.DataReducida[this.sensores[index][0]+1].map(Number)//Falta Cambiar por correspondiente
    //      this.datasensor[1].data=this.selected.DataReducida[this.sensores[index][1]+1].map(Number)
    //      this.datasensor[2].data=this.selected.DataReducida[this.sensores[index][2]+1].map(Number)
    //      this.labelsensor=this.selected.DataReducida[0].map(Number)
  
    //     }
    //     else
    //     {
    //       if(this.data.substring(0,2)=="ST")
    //       {
    //         this.datasensor=[{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"A"},{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"B"},
    //         {data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"C"}]
    //        this.datasensor[0].data=this.selected.DataReducida[this.sensores[index][0]+1].map(Number)//Falta Cambiar por correspondiente
    //        this.datasensor[1].data=this.selected.DataReducida[this.sensores[index+1][0]+1].map(Number)
    //        this.datasensor[2].data=this.selected.DataReducida[this.sensores[index+2][0]+1].map(Number)
    //        this.labelsensor=this.selected.DataReducida[0].map(Number)
    //       }
    //       else{
    //       this.datasensor[0].data=this.selected.DataReducida[this.sensores[index][0]+1].map(Number)
    //       this.labelsensor=this.selected.DataReducida[0].map(Number)
    //         }
    //     }
    //      try {
    //      this.selectedId= this.selected["ID"]
    //      console.log("Selected",res["Items"])
    //     }     
    //     catch (error) {
    //       console.warn(error);
          
    //       // expected output: ReferenceError: nonExistentFunction is not defined
    //       // Note - error messages will vary depending on browser
    //     }
    //     this.events.map(function(el){el["Tinit"] = new Date(el["Tinit"]*1000+60*60*3000)})
    //     this.hayeventos=true
    //     this.cargando=false
        
    //   })
    // }
    
    


 }

 stringArrayToFLoat(array)
 // Función de apoyo, ya que algunos arreglos vienen con los datos en string y se pasan a float.
 {
   return array.map(function(el){return parseFloat(el).toFixed(2)})
 }

 populate()
 {
    // Toma la información que se obtuvo del backend y la transforma en los formatos requeridos por los gráficos
    
    var selected = this.selected
    var events = this.events
    this.databar={datachart:[[],[]],dataranges:[]}
    this.specdata=[
      { data: [], label: 'Sx1' },
      { data: [], label: 'Sy1' }, 
      { data: [], label: 'Sz1' },
      { data: [], label: 'Sx2' },
      { data: [], label: 'Sy2' }, 
      { data: [], label: 'Sz2' },
      { data: [], label: 'Sx3' },
      { data: [], label: 'Sy3' }, 
      { data: [], label: 'Sz3' },
      { data: [], label: 'Sx4' },
      { data: [], label: 'Sy4' }, 
      { data: [], label: 'Sz4' },
    ]
  

    if(!this.initialize){events.map(function(el){el["Tinit"] = new Date(el["Tinit"]*1000+60*60*4000)})}

    console.log("Selected",this.selected)
    selected["Histograma"][0]["Quant"].forEach(element=> {this.databar.datachart[0].push({data:this.stringArrayToFLoat(element), label: 'Distribución de datos' })})
    //selected["Histograma"][2]["Hist"].forEach(element=> { this.databar.datachart[1].push({data:this.stringArrayToFLoat(element), label: 'Histograma de Datos (Histórico)' })});
    console.log('C1')
    this.databar.dataranges=selected["Histograma"][1]["Ranges"][1]
    
    this.histonchange=!this.histonchange;

     
    this.specfrecs=[]
    this.rmfrecs=[]
    var speclen=Math.min(selected["Espectros"].length,12)
    console.log('CX:',speclen)



    for (var i = 0; i < speclen; i++) {
       this.specdata[i].data=selected["Espectros"][i];
       //this.rmdata[i].data=selected["RM"][i];
       //this.specfrecs.push({label:this.specdata[i].label,data: selected["Frecuencias_Naturales"][i]})
       //this.rmfrecs.push({label:this.rmdata[i].label,data: selected["Frecuencias_Naturales"][i]})
    }
    
    this.specdata = this.specdata.slice(0,speclen)
    //this.rmdata = this.rmdata.slice(0,speclen)
    this.speclabel=[]//this.data[0].Espectros[0][1];


    
    this.envdata=[
    ]

    for (var i = 0; i < this.puntosenvacc.length; i++) {
this.envdata.push({data:[{y:this.puntosenvacc[i][2],x:this.puntosenvacc[i][1]}],label:this.puntosenvacc[i][0],
  backgroundColor: 'rgba(120,200,200,0)',
  borderColor: 'rgba(150,200,200,1)',
  pointBackgroundColor: 'rgba(250,50,50,1)',
  pointBorderColor: 'rgba(200,50,50,1)',
  pointHoverBackgroundColor: '#fff',
  pointHoverBorderColor: 'rgba(77,83,96,1)'

})

    }

    this.envdata.push({ data: this.dataenv[0], label: 'AccMax' ,
    backgroundColor: 'rgba(255,200,100,0.5)',
    borderColor: 'rgba(255,200,100,1)',
    pointBackgroundColor: 'rgba(200,200,255,0)',
    pointBorderColor: 'rgba(10,10,50,0)',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(77,83,96,1)'},)
    this.envdata.push({ data: this.dataenv[1], label: 'AccMin',
    backgroundColor: 'rgba(255,200,100,0.5)',
    borderColor: 'rgba(255,200,100,1)',
    pointBackgroundColor: 'rgba(200,200,255,0)',
    pointBorderColor: 'rgba(10,10,50,0)',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(77,83,96,1)' },)

    
    this.envdata2=[
    ]

    for (var i = 0; i < this.puntosenvsg.length; i++) {
      this.envdata2.push({data:[{y:this.puntosenvsg[i][2],x:this.puntosenvsg[i][1]}],label:this.puntosenvsg[i][0],
        backgroundColor: 'rgba(120,200,200,0)',
        borderColor: 'rgba(150,200,200,1)',
        pointBackgroundColor: 'rgba(250,50,50,1)',
        pointBorderColor: 'rgba(200,50,50,1)',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(77,83,96,1)'
      })}

      this.envdata2.push({ data: this.dataenv[2], label: 'DefMax',
      backgroundColor: 'rgba(255,200,100,0.5)',
      borderColor: 'rgba(255,200,100,1)',
      pointBackgroundColor: 'rgba(200,200,255,0)',
      pointBorderColor: 'rgba(10,10,50,0)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)' })

      this.envdata2.push({ data: this.dataenv[3], label: 'DefMin' ,
      backgroundColor: 'rgba(255,200,100,0.5)',
      borderColor: 'rgba(255,200,100,1)',
      pointBackgroundColor: 'rgba(200,200,255,0)',
      pointBorderColor: 'rgba(10,10,50,0)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'})
    console.log("Specters y Env",this.specdata,this.envdata)
    //this.fillRadarData(selected, events)

    this.initialize=true
   
  }


  ngOnInit(): void {

    

    // var params = {
    //   TableName: 'Sensores',
    //   Key: {
    //     'Id': {S: this.data}
    //   },
    //   ProjectionExpression: 'Id, StateS, TypeS, LC, Description'
    // };
    // this.awsservice.GetItem(params).then(res=>
    //   {
    //     console.log("Sensor",res);
    //     var dt=res.Item;
    //     this.sensores=[{Id:dt.Id.S,StateS:dt.StateS.S,LC:dt.LC.S,Description:dt.Description.S,TypeS:dt.TypeS.S}];

    //   });
    
  }

  NavigatePrev()
  {
    console.log(this.previous)
    this.router.navigate(["Assets",this.previous]);

  }

  
  Prev_Ev()
  {

   console.log("Next",this.LEK[this.LEKidx])
   if(this.LEKidx>1){
     var clave = this.activatedRoute.params["_value"].id+"X"+this.LEK[this.LEKidx-2]
     this.http.get(`${environment.apiUrl}/api/events/${clave}`).subscribe(res => 
       {
         console.log("Next", res, this.LEK)
         this.selected = res["Items"][0]
         this.LastEvKey=res['LastEvaluatedKey'].ID
            
         if(this.LEKidx>1){this.LEKidx-=1}
         
        this.events = res["Items"]
        console.log("Events",res)
        this.dataS=this.events
        try {
        this.selectedId= this.selected["ID"]
        console.log("Selected",this.selected)
       }
       catch (error) {
         console.warn(error);
         // expected output: ReferenceError: nonExistentFunction is not defined
         // Note - error messages will vary depending on browser
       }
       try{
       this.initialize=false
       }
       catch(error){
         console.warn(error);
       }
       })
   }
   
  }
  Next_Ev()
  {
   console.log("Next",this.LEK[this.LEKidx])
   var clave = this.activatedRoute.params["_value"].id+"X"+this.LEK[this.LEKidx]
   this.http.get(`${environment.apiUrl}/api/events/${clave}`).subscribe(res => 
     {
       console.log("Next", res, this.LEK)
       this.selected = res["Items"][0]
       this.LastEvKey=res['LastEvaluatedKey'].ID
       
       
       this.LEK.push(res['LastEvaluatedKey'].ID)
       
     
       this.LEKidx+=1
      this.events = res["Items"]
      console.log("Events",res)
      this.dataS=this.events
      try {
      this.selectedId= this.selected["ID"]
      console.log("Selected",this.selected)
     }
     catch (error) {
       console.warn(error);
       // expected output: ReferenceError: nonExistentFunction is not defined
       // Note - error messages will vary depending on browser
     }
     try{
     this.initialize=false
     }
     catch(error){
       console.warn(error);
     }
     })

  }

  next(i)
  {
    this.cargando=true
    this.hayeventos=false
    document.getElementById("dropdownsel").innerHTML = i;
    if (this.data.substring(0,2)=='AC')
    {
      var consulta=this.data+i+'_SGS_'+this.previous//this.data+
      console.log(consulta)
      
      this.http.get(`${environment.apiUrl}/api/events2/${consulta}`).subscribe(res => 
        {
          console.log("Response",res) 
         this.selected = res["Items"][0]
           
         this.events = res["Items"]
         this.cargando=false
          var sens:any=res["Items"][0].Sensor
          if(sens){this.hayeventos=true} 
         this.sensores=[] 
         var contador=0;
         for (var i = 0; i < sens.length; i++) {
           if(sens[i].substring(0,2)=="AC"){
            this.sensores.push([contador,contador+1,contador+2])
            contador+=3
           }
           else
           {
            this.sensores.push([contador])
            contador+=1
           }
  
          
          }
          
          var index=sens.indexOf(this.data+"A")
          if(this.data.substring(0,2)!="ST")
          {
            index=sens.indexOf(this.data)
          }
          console.log(this.sensores,index)//,this.sensores[index][0]+1)
          
         if(this.data.substring(0,2)=="AC"){
  
          this.datasensor=[{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"Ax"},{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"Ay"},
          {data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"Az"}]
         this.datasensor[0].data=this.selected.DataReducida[this.sensores[index][0]+1].map(Number)//Falta Cambiar por correspondiente
         this.datasensor[1].data=this.selected.DataReducida[this.sensores[index][1]+1].map(Number)
         this.datasensor[2].data=this.selected.DataReducida[this.sensores[index][2]+1].map(Number)
         this.labelsensor=this.selected.DataReducida[0].map(Number)
  
        }
        else
        {
          if(this.data.substring(0,2)=="ST")
          {
            this.datasensor=[{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"A"},{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"B"},
            {data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"C"}]
           this.datasensor[0].data=this.selected.DataReducida[this.sensores[index][0]+1].map(Number)//Falta Cambiar por correspondiente
           this.datasensor[1].data=this.selected.DataReducida[this.sensores[index+1][0]+1].map(Number)
           this.datasensor[2].data=this.selected.DataReducida[this.sensores[index+2][0]+1].map(Number)
           this.labelsensor=this.selected.DataReducida[0].map(Number)
          }
          else{
          this.datasensor[0].data=this.selected.DataReducida[this.sensores[index][0]+1].map(Number)
          this.labelsensor=this.selected.DataReducida[0].map(Number)
            }
        }
         try {
         this.selectedId= this.selected["ID"]
         console.log("Selected",res["Items"])
        }     
        catch (error) {
          console.warn(error);
          
          // expected output: ReferenceError: nonExistentFunction is not defined
          // Note - error messages will vary depending on browser
        }
        this.cargando=false
        this.events.map(function(el){el["Tinit"] = new Date(el["Tinit"]*1000+60*60*3000)})
      })
    }

    if (this.data.substring(0,2)=='ST')
    {
      var consulta=this.data+i+'_SGS_'+this.previous//this.data+
      console.log(consulta)
      this.http.get(`${environment.apiUrl}/api/events2/${consulta}`).subscribe(res => 
        {
          console.log("Response",res)
         
        
          
         this.selected = res["Items"][0]   
         this.events = res["Items"]
         this.cargando=false
          var sens:any=res["Items"][0].Sensor
          if(sens){this.hayeventos=true} 
         this.sensores=[] 
         var contador=0;
         for (var i = 0; i < sens.length; i++) {
           if(sens[i].substring(0,2)=="AC"){
            this.sensores.push([contador,contador+1,contador+2])
            contador+=3
           }
           else
           {
            this.sensores.push([contador])
            contador+=1
           }
  
          
          }
          
          var index=sens.indexOf(this.data+"A")
          if(this.data.substring(0,2)!="ST")
          {
            index=sens.indexOf(this.data)
          }
          console.log(this.sensores,index)//,this.sensores[index][0]+1)
          
         if(this.data.substring(0,2)=="AC"){
  
          this.datasensor=[{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"Ax"},{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"Ay"},
          {data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"Az"}]
         this.datasensor[0].data=this.selected.DataReducida[this.sensores[index][0]+1].map(Number)//Falta Cambiar por correspondiente
         this.datasensor[1].data=this.selected.DataReducida[this.sensores[index][1]+1].map(Number)
         this.datasensor[2].data=this.selected.DataReducida[this.sensores[index][2]+1].map(Number)
         this.labelsensor=this.selected.DataReducida[0].map(Number)
  
        }
        else
        {
          if(this.data.substring(0,2)=="ST")
          {
            this.datasensor=[{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"A"},{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"B"},
            {data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"C"}]
           this.datasensor[0].data=this.selected.DataReducida[this.sensores[index][0]+1].map(Number)//Falta Cambiar por correspondiente
           this.datasensor[1].data=this.selected.DataReducida[this.sensores[index+1][0]+1].map(Number)
           this.datasensor[2].data=this.selected.DataReducida[this.sensores[index+2][0]+1].map(Number)
           this.labelsensor=this.selected.DataReducida[0].map(Number)
          }
          else{
          this.datasensor[0].data=this.selected.DataReducida[this.sensores[index][0]+1].map(Number)
          this.labelsensor=this.selected.DataReducida[0].map(Number)
            }
        }
         try {
         this.selectedId= this.selected["ID"]
         console.log("Selected",res["Items"])
        }     
        catch (error) {
          console.warn(error);
          
          // expected output: ReferenceError: nonExistentFunction is not defined
          // Note - error messages will vary depending on browser
        }
        this.cargando=false
        this.events.map(function(el){el["Tinit"] = new Date(el["Tinit"]*1000+60*60*3000)})
      })
    }
    
  }

  DownloadFileS3(event)
  {
    var url=this.events[parseInt(event)-1]["FileUrl"];
    console.log(url)
    var bucket=url.split('/')[3];
    var key=url.split('/')[4];
    var params=bucket+"!"+key
    console.log("FileDownload",bucket,key);
    this.http.get(`${environment.apiUrl}/api/download/${bucket}/${key}`).subscribe(res => 
      { 
          console.log("ok download", res)
          window.location.assign(res.toString());
          
      })
  } 
  ReloadGraphs(event)
  {
    console.log("datachange")
    if (this.events)
    {
      this.selected=this.events[event-1]
      var sens =this.selected.Sensor
      var index=sens.indexOf(this.data+"A")
      if(this.data.substring(0,2)!="ST")
      {
        index=sens.indexOf(this.data)
      }
        console.log(this.sensores,index)//,this.sensores[index][0]+1)
        
       if(this.data.substring(0,2)=="AC"){

        this.datasensor=[{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"Ax"},{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"Ay"},
        {data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"Az"}]
       this.datasensor[0].data=this.selected.DataReducida[this.sensores[index][0]+1].map(Number)//Falta Cambiar por correspondiente
       this.datasensor[1].data=this.selected.DataReducida[this.sensores[index][1]+1].map(Number)
       this.datasensor[2].data=this.selected.DataReducida[this.sensores[index][2]+1].map(Number)
       this.labelsensor=this.selected.DataReducida[0].map(Number)

      }
      else
      {
        if(this.data.substring(0,2)=="ST")
        {
          this.datasensor=[{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"A"},{data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"B"},
          {data:[-1,0,1,-1,0,1,-1,0,1,-1,0,1,-1,0,1],label:"C"}]
         this.datasensor[0].data=this.selected.DataReducida[this.sensores[index][0]+1].map(Number)//Falta Cambiar por correspondiente
         this.datasensor[1].data=this.selected.DataReducida[this.sensores[index+1][0]+1].map(Number)
         this.datasensor[2].data=this.selected.DataReducida[this.sensores[index+2][0]+1].map(Number)
         this.labelsensor=this.selected.DataReducida[0].map(Number)
        }
        else{
        this.datasensor[0].data=this.selected.DataReducida[this.sensores[index][0]+1].map(Number)
        this.labelsensor=this.selected.DataReducida[0].map(Number)
          }
      }

      

      //this.datasensor[0].data=this.selected.DataReducida[1].map(Number)
      // this.labelsensor=this.selected.DataReducida[0].map(Number)
      // console.log("DataChange", this.data)
      // this.databar.datachart[0]=[];
      // this.data[id-1]["Histograma"][0]["Quant"].forEach(element=>
      //   {this.databar.datachart[0].push({data:element,label: 'Histograma de Datos (Último Evento)' })});
      
      // this.databar.dataranges=this.data[id-1]["Histograma"][1]["Ranges"][1];
      // this.histonchange=!this.histonchange;

      // this.fillRadarData(this.data[id-1], this.data)

      // this.specfrecs=[]
     
      // this.RefreshFRS(id-1);
    }
  } 

  fillRadarData(selected, events)
  {
    
    
    this.labelRadarMean= [];
    this.labelRadarPeaks= [];

    var rango = []
    for (var i = 0; i < selected.PGA[0].length; i++) { rango.push(i)}

    var media_historico = rango.map(function(index){ return events.reduce(function (suma, el) {  return suma + parseFloat(el.PGA[1][index]) / events.length;}, 0);})
    var peak_historico = rango.map(function(index){ return events.reduce(function (suma, el) {  return suma + parseFloat(el.PGA[0][index]) / events.length;}, 0);})

    var media_referencia = rango.map(function(index){ return 1})
    var peak_referencia = rango.map(function(index){ return 1})

    var media_selected = selected.PGA[1].map(function(el){return parseFloat(el)})
    var peak_selected = selected.PGA[0].map(function(el){return parseFloat(el)})


    peak_referencia = peak_referencia.map(function(each_element){
      return Number(each_element.toFixed(2));
  });
  peak_selected = peak_selected.map(function(each_element){
    return Number(each_element.toFixed(2));
});
peak_historico = peak_historico.map(function(each_element){
  return Number(each_element.toFixed(2));
});
media_referencia = media_referencia.map(function(each_element){
  return Number(each_element.toFixed(2));
});
media_selected = media_selected.map(function(each_element){
  return Number(each_element.toFixed(2));
});
media_historico = media_historico.map(function(each_element){
  return Number(each_element.toFixed(2));
});

    this.dataRadarPeaks=[
      { data: peak_referencia, label: 'Referencia Peaks' },
      { data: peak_selected, label: 'Evento Peaks' },  
          
    ]

    this.dataRadarMean=[
      { data: media_referencia, label: 'Referencia Medias' },
      { data: media_selected, label: 'Evento Medias' }, 
        
      ]


    //Labels
    console.log("Sensores?",this.selected)
    selected.Sensor.forEach(element=> {
      if(element.substring(0,2)=="AC")
      {
        ['X'].forEach(indice=>
        {
          this.labelRadarMean.push(`${element.substring(0,5)}`);  
          this.labelRadarPeaks.push(`${element.substring(0,5)}`);
        })
      }
      else
      {
        this.labelRadarMean.push(element.substring(0,5))  
        this.labelRadarPeaks.push(element.substring(0,5))
      }
    });

  }




}
