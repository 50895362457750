<h1>Search Result ...</h1>
<hr>

<div class="card-columns">
    
    <app-search-card (selectedAsset)="Navigate($event)" [asset]="asset" [index]="asset.idx" *ngFor="let asset of assets; let i = index"></app-search-card>
      


      
    
  </div>
