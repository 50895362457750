import { Component, OnInit ,Input} from '@angular/core';
import { ChartDataSets, ChartType, RadialChartOptions } from 'chart.js';
import { Label ,Color} from 'ng2-charts';

@Component({
  selector: 'app-radarbar',
  templateUrl: './radarbar.component.html',
  styles: [
  ]
})
export class RadarbarComponent implements OnInit {

  @Input() data:any;

  public radarChartOptions: RadialChartOptions = {
    responsive: true,
    
    scale: {
      
      pointLabels: {
        fontSize: 8,
        
        //fontStyle: "bold",
        		
      },
      ticks: {
          suggestedMin: 0,
          fontSize: 8,
          
          
          //suggestedMax: 1
      }
      

  },
  legend: {
    display: true,
    labels: {
        fontSize: 8,
        //fontStyle: "bold",
    }
},
    
  };
  @Input() radarChartLabels: Label[];

  //public radarChartData: ChartDataSets[] = this.data;
  public radarChartType: ChartType = 'radar';

  constructor() { }

  ngOnInit() {
  }

  public BarChartColors: Color[] = [
    // yellow
    { // celeste
      backgroundColor: 'rgba(150,150,200,0.5)',
      borderColor: 'rgba(200,200,255,1)',
      pointBackgroundColor: 'rgba(77,83,96,0)',
      pointBorderColor: 'rgba(77,83,96,0)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
     { // azul
       backgroundColor: 'rgba(70,70,120,0.5)',
       borderColor: 'rgba(80,80,80,1)',
       pointBackgroundColor: 'rgba(77,83,96,0)',
       pointBorderColor: 'rgba(77,83,96,0)',
       pointHoverBackgroundColor: '#fff',
       pointHoverBorderColor: 'rgba(77,83,96,1)'
     },
     { // zul oscuro
      backgroundColor: 'rgba(30,30,80,0.5)',
      borderColor: 'rgba(20,20,20,1)',
      pointBackgroundColor: 'rgba(77,83,96,0)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
   
 ];


  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
}
