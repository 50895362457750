import { Component, Input} from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';

@Component({
  selector: 'app-specline',
  templateUrl: './specline.component.html',
  styles: [
  ]
})
export class SpeclineComponent {

  
  @Input() lineChartData: any[];
  // = [
  //   { data: [100, 110, 90, 81, 56, 55, 40], label: 'Sx' },
  //   { data: [100, 130, 90, 90, 86, 70, 20], label: 'Sy' },
  //   { data: [120, 140, 95, 45, 50, 27, 20], label: 'Sz' }
  // ];
  @Input() specfrecs: any[];
  @Input() lineChartLabels: any[]; 
  // = ['0.1', '1', '5', '10', '20', '40', '80'];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    legend:
    {
      labels:{ 
        //usePointStyle: true,
      }
      
    },
    showLines: true,
    scales: {
      //xAxes: [{scaleLabel: {display: true, labelString: 'Rango (umbral=1)'}}], yAxes: [{scaleLabel: {display: true, labelString: '%'}}] },
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
       // display: false,
       //type: 'logarithmic',
       //interval: 10,       
       //type: 'logarithmic',
      //position: 'left',
      scaleLabel: {
        labelString: 'Frecuencia (Hz)',
        display: true,
        
      },
      ticks: {
        //     fontColor: 'red',
        min:0.5,
        callback: function(value:number, index, values) {
          if ([0.5,1,2,5,10,20,50].includes(value))
          {
            return value
          }
            
          return "";
      }
           },
      type: 'logarithmic',
      }],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          scaleLabel: {display: true, labelString: 'Magnitud [mg]'}
        },
        
        
        // {
        //   id: 'y-axis-1',
        //   position: 'right',
        //   gridLines: {
        //     color: 'rgba(120,120,120,0.3)',
        //   },
        //   ticks: {
        //     fontColor: 'red',
        //   }
        // }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'spline',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };

  
  public lineChartColors: Color[] = [


    { // rojo claro
      backgroundColor: 'rgba(20,20,200,0)',
      borderColor: 'rgba(200,120,120,1)',
      pointBackgroundColor: 'rgba(255,200,200,0)',
      pointBorderColor: 'rgba(50,10,10,0)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
     { // azul oscuro
       backgroundColor: 'rgba(120,120,200,0)',
       borderColor: 'rgba(150,150,200,1)',
       pointBackgroundColor: 'rgba(200,200,255,0)',
       pointBorderColor: 'rgba(10,10,50,0)',
       pointHoverBackgroundColor: '#fff',
       pointHoverBorderColor: 'rgba(77,83,96,1)'
     },
     { // verde oscuro
      backgroundColor: 'rgba(120,200,120,0)',
      borderColor: 'rgba(120,200,120,1)',
      pointBackgroundColor: 'rgba(200,255,200,0)',
      pointBorderColor: 'rgba(10,50,10,0)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // rojo claro
      backgroundColor: 'rgba(20,20,200,0)',
      borderColor: 'rgba(200,200,120,1)',
      pointBackgroundColor: 'rgba(255,255,200,0)',
      pointBorderColor: 'rgba(50,50,10,0)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
     { // azul oscuro
       backgroundColor: 'rgba(120,200,200,0)',
       borderColor: 'rgba(150,200,200,1)',
       pointBackgroundColor: 'rgba(200,255,255,0)',
       pointBorderColor: 'rgba(10,50,50,0)',
       pointHoverBackgroundColor: '#fff',
       pointHoverBorderColor: 'rgba(77,83,96,1)'
     },
     { // verde oscuro
      backgroundColor: 'rgba(200,120,200,0)',
      borderColor: 'rgba(200,120,200,1)',
      pointBackgroundColor: 'rgba(255,200,255,0)',
      pointBorderColor: 'rgba(50,10,50,0)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
  
   
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';

  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

}
