<form>
    
      <div class="form-group col-md-4">
        <label for="inputStruct">Structure</label>
        <select (change)="Structure()" id="inputStruct" class="form-control">
          <option selected>NON-None</option>
          <option *ngFor="let item of assets">{{item.clave}}-{{item.nombre}}</option>
        </select>
      </div>
      <div class="form-group col-md-4">
        <label for="inputPreIDInput">Id</label>
        <input class="form-control" id="PreIDInput" type="text" placeholder="{{PreID|uppercase}}" disabled>
        <input class="form-control" id="IDInput" type="number" placeholder="0" enabled>
      </div>
      <div class="form-group col-md-4">
        <label for="inputType">Type</label>
        <select id="inputType" class="form-control" (change)="Type()" required>
          <option >ACCELEROMETER</option>
          <option>STRAIN GAUGE</option>
          <option>DISTANCIOMETER</option>
        </select>
      </div>
      <div class="form-group col-md-4">
        <label for="inputDescription">Description</label>       
          <input class="form-control" id="DescriptionInput" type="text" placeholder="Write something about the sensor" required>
      </div>
      <div class="form-group col-md-4">
        <label for="inputState">State</label>
        <select id="inputState" class="form-control" required>
          <option selected>Inactive</option>
          <option >Active</option>
          <option >Manteinance</option>
          <option >Error</option>
          
        </select>
      </div>
      <div class="form-group col-md-4">
        <label for="LCinput">Last Connection</label>
        <input class="form-control" id="LCInput" type="text" placeholder="None" disabled>
      </div>
    <button (click)="Submit()" type="submit" class="btn btn-primary">Submit</button>
  </form>