
        <div >
        <canvas baseChart width="400" height="400"
                    [datasets]="lineChartData"
                    [labels]="lineChartLabels"
                    [options]="lineChartOptions"
                    [colors]="lineChartColors"
                    [legend]="lineChartLegend"
                    [chartType]="lineChartType"
                    
                    (chartHover)="chartHovered($event)"
                    (chartClick)="chartClicked($event)"
                    style="max-height: 500px;"
                    ></canvas>
        </div>
  