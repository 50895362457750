import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-mapimg',
  templateUrl: './mapimg.component.html',
  styleUrls: ['./mapimg.component.css']
})
export class MapimgComponent implements OnInit {

  constructor() { }
  
  @Input() imgsource="./assets/images/mapaload.png"
  ngOnInit() {
  }

}
