import { Component, OnInit ,Input} from '@angular/core';

@Component({
  selector: 'app-clickimg',
  templateUrl: './clickimg.component.html',
  
})
export class ClickimgComponent implements OnInit {
@Input() connection='0 %'
@Input() imgsource="./assets/images/test.png";
@Input() mapcoords=["315,52,278,73,325,103,352,79","797,339,757,359,855,410,895,389",
"413,153,453,132,486,153,451,179","581,235,578,265,634,298,634,271","308,265,311,321,341,316,344,245"];
args1=", TC: "
args2=" %"
  constructor() { }
@Input() sensorref="Sensor/"

  ngOnInit(): void {
  }

}
