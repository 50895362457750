import { Component, OnInit ,Input} from '@angular/core';
import { SensoresComponent } from '../../sensores/sensores.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sensor-table',
  templateUrl: './sensor-table.component.html',
  styles: [
  ]
})
export class SensorTableComponent implements OnInit {

  @Input() data_Sensores;
  @Input() Description;
  @Input() LastConection;
  @Input() Number;
  @Input() Prev;
  constructor(private router:Router) { }


  ngOnInit(): void {
  }
  buscarSensor(value,number?)
  {
    console.log(value,this.Prev)
    //console.log(number)
    this.router.navigate(["Sensor",this.Prev,value]);

  }

}
