<table class="table">
    <thead>
        <th *ngIf="Number" width="20%" scope="col">#</th>
      <th width="20%" scope="col">Sensor</th>
      <th width="20%" scope="col">State</th>
      <th width="20%">Type</th>
      <th width="20%" *ngIf="Description" >Description</th>
      <th width="20%" *ngIf="LastConection">Last Report</th>
    </thead> 
    <tbody>
    <tr *ngFor="let item of data_Sensores;let i = index">
        <td *ngIf="Number" #number>{{i+1}}</td>
        <td #IdSensor>{{item.Id}}</td>
        <td>
            <h3><span *ngIf="item.StateS == 'Active'" class="badge badge-pill badge-primary">
                <i class="fas fa-check-circle"></i>   
                Active
            </span></h3>
            <h3><span *ngIf="item.StateS == 'Error'" class="badge badge-pill badge-danger">
                <i class="fas fa-exclamation-triangle"></i>
                Error</span></h3>
            <h3><span *ngIf="item.StateS == 'Manteinance'"  class="badge badge-pill badge-warning">
                <i class="fa fa-bolt"></i>
                Manteinance</span></h3>
            <h3><span *ngIf="item.StateS == 'Inactive'"  class="badge badge-pill badge-secondary">
                <i class="fas fa-spinner"></i>
                Inactive</span></h3>
        </td>
        <td>{{item.TypeS}}</td>
        <td *ngIf="Description">
            <button (click)="buscarSensor(IdSensor.innerHTML)" 
        class="btn btn-outline-primary my-2 my-sm-0" type="button">See Details...</button>
        </td>
        <td *ngIf="LastConection">{{item.LC}}</td>
    </tr>
    </tbody>


  </table>
