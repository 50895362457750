import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import flvjs from 'flv.js';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-camaralive',
  templateUrl: './camaralive.component.html',
  styleUrls: ['./camaralive.component.css']
})
export class CamaraliveComponent implements OnInit {

  loadCameraError = null;
  thumb = '';
  url = '';
  cameraName='';
  controls = false;
  played = false;


  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params
      .subscribe(params => {
        
        if ((params.id)) {
          
          this.loadVideo(params.id);
        }
      })
  }

  async loadVideo(idDevice) {
    this.loadCameraError = null;
    this.controls = false

    environment.urlList.map((value) => {
      if (value.name == idDevice) {
        console.log(value)
        this.url = value.url;
        this.thumb = value.thumb
        this.cameraName = value.name
        this.loadCameraError = false;
        
      }
    });
    
  }

  play() {
    
    if (flvjs.isSupported()) {
      this.controls = true;
      
      if (!this.played) {
        const videoElement = <HTMLAudioElement>document.getElementById('videoElement');
        const flvPlayer = flvjs.createPlayer(
          {
            type: 'flv',
            url: this.url,
          });
        
        flvPlayer.attachMediaElement(videoElement);
        flvPlayer.load();
        flvPlayer.play();
        this.played = true;
        
        flvPlayer.on(flvjs.Events.ERROR, (error) => {
          
          if (error) {
            console.warn('Error', error)
            flvPlayer.detachMediaElement();
            flvPlayer.unload();
            this.loadCameraError = true;
          } else {
            this.loadCameraError = false;            
          }
        });

      }
    }
  }

}
