import { Injectable } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment'
import { ActivatedRoute} from '@angular/router';
import { AssetsService } from '@app/__services/assets.service';
import { logging } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class MapCustomService {

  mapbox = (mapboxgl as typeof mapboxgl);
  map:mapboxgl.Map | undefined;
  style="mapbox://styles/mapbox/satellite-v9";
  asset=JSON.parse(localStorage.getItem("asset"))['clave']
  
  assets={'SHL':[-23.756920,-70.465008,"Shiploader"],'CV8':[-23.757979,-70.46553,"CV-08"],"SPLC":[-24.26342,-69.060673,"Stockpile LC"]
  ,"RTP":[-24.233698,-69.153442,"Rotopala"],"TRBLC":[-24.263238,-69.060683,"Tripper B LC"],"SPLS1":[-24.344184,-69.059679,"Stockpile LS1"],
    "TRL1LS":[-24.343974,-69.059590,"Tripper L1 LS"],"SPLS2":[-24.345439,-69.060042, "Stockpile LS2"],"TRL2LS":[-24.345627,-69.059775,"Tripper LS2"],
    "CV104":[-24.341542,-69.057935,"CV-104"],"CH5":[-24.217165,-69.038574,"Chancador 5"],"CV238":[-24.346304,-69.057849,"CV-238"],
    "CV239":[-24.346093,-69.058623,"CV-239"],"CV12":[-24.342229,-69.060946,"CV-12"],"CV5009":[-24.264218,-69.059153,"CV-5009"],
    "CV5010":[-24.264654,-69.059191,"CV-5010"],"CV5012":[-24.263453,-69.059787,"CV-5012"],"HC":[-24.263606,-69.059585,"Hidrociclones"]}

  keys=Object.keys(this.assets)
  lat=this.assets[this.asset][0];
  lng=this.assets[this.asset][1];
  zoom=14;
  activos:any;
  geolocations=[] as any


  constructor( private activatedRoute:ActivatedRoute,private http: HttpClient) { 
   this.mapbox.accessToken=environment.mapboxKey;
   console.log('MBKey',this.mapbox.accessToken)
   var clave='DICTUC'
   console.log("MAPA",this.lat,this.lng)
    

  }

  buildMap():Promise<any>
  {
    this.asset=JSON.parse(localStorage.getItem("asset"))['clave']
    this.lat=this.assets[this.asset][0];
    this.lng=this.assets[this.asset][1];
    return new Promise((resolve,reject) => {

      try{
        console.log("1okok")
        this.map=new mapboxgl.Map({
          container:'map',
          style: this.style,
          zoom: this.zoom,
          center: [this.lng,this.lat],
          accessToken:environment.mapboxKey
        })
        
//-33.419957199453684, -70.60128803410745

        
        var geolocs=this.assets

        
        for (var i = 0; i < (this.keys as any).length; i++)
          {
              var geol=[parseFloat(geolocs[this.keys[i]][1]),parseFloat(geolocs[this.keys[i]][0])]
              this.geolocations.push(geol)
              

              var marker = new mapboxgl.Marker({
                draggable: false
                })
                .setLngLat(geol as any).setPopup(new mapboxgl.Popup({ offset: 40 })
                .setHTML('<p style="color: darkslategrey; margin-top: 50%;">'+geolocs[this.keys[i]][2]+'</p>'))
                .addTo(this.map);
          }

        console.log('Geolocs',this.geolocations)
        

        
          resolve(this.map)
        
      }
      catch(e)
      {
        reject(e)
      }
        

    });
    
  }
}
