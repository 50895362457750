<app-navbar  style=""></app-navbar>
<!-- <app-navbar *ngIf="winwidth>800 && winwidth<1600" style="zoom: 66%;"></app-navbar> -->
<br>

<div class="row" >
   
<!-- <div class="col" style="text-align: center; max-width: 25vw; ">
      <div class="card-body" style="">
         <h4>Activos Físicos Conectados: {{connected}}/{{Total}}</h4>
         <div class="row" style="display: block;"> 
            <button (click)="DownloadFileS3()"  class="btn btn-primary my-2 my-sm-0" type="button">Descargar Informe <i class="fas fa-download"></i></button> 
         </div>
      </div>
      <hr>
      <div style="zoom: 65%;"> 
         <h3 style="display: flex;justify-content: center; align-items: center; ">Ultimas 5 Alertas</h3>
         <br>
         <div class="card-body"  style="overflow-y: auto; max-height: 35vh; zoom: 80%;">
            <div style="margin: 0%;">
               <table class="table" >
                   <thead>
                      <th>Activo</th>
                       <th >Fecha</th>
                       <th >Alerta</th>   
                       <th>Causa</th>   
                       <th ></th>
           
                   </thead>
                   <tbody > 
                       <tr *ngFor="let item of eventos; let i = index" style="max-height: 25px; margin: 0%;">
                           <td>{{item.Lugar}} </td>
                           <td  #number>{{item.Tinit | date:'dd MMM yyyy HH:mm:ss'}}</td> 
                           <td *ngIf="item.EventoCausa.split('|')[1]=='Check'"  #idnumber><i class="fa fa-circle"></i></td> 
                           <td *ngIf="item.EventoCausa.split('|')[1]=='Rojo'"  #idnumber><i class="fa fa-circle" style="color: red;"></i></td> 
                           <td *ngIf="item.EventoCausa.split('|')[1]=='Amarillo'"  #idnumber><i class="fa fa-circle" style="color: yellow;"></i></td> 
                           <td *ngIf="item.EventoCausa.split('|')[1]=='Verde'"  #idnumber><i class="fa fa-circle" style="color: green;"></i></td> 
                           <td *ngIf="item.EventoCausa.split('|')[1]=='Azul'"  #idnumber><i class="fa fa-circle" style="color: blue"></i></td> 
                           <td>{{item.EventoCausa.split('|')[0]}}</td>   
                           <td > <button 
                           class="btn btn-outline-primary my-2 my-sm-0" type="button" (click)="Ir(item.Lugar.split('_')[1],item.ID)" style="max-height: 35px; margin: 0%;"   >Ir</button>
                               
                              
                           </td>
                       </tr>
               </tbody>
             </table>
           </div>
        </div>

      </div>
     
   <hr>
   <div class="card-body" style="zoom: 70%;">
      <h3 style="text-align: center;">Eventos Semanales</h3>
      <div class="row" style="display: block;">
         <app-histo-events *ngIf="Loaded" [barChartLabels]='ranges' [barChartData]='databar' [Loaded]="Loaded"></app-histo-events>
      </div>
   </div>
  
</div> -->

<div class="col" style="max-height: 180vh; overflow-y: auto ; zoom: 80%;">
   <div class="row">
      <div class="column" *ngFor="let asset of assets; let i = index">
     
         <app-search-card [asset]="asset" ></app-search-card>  
          
        </div>

   </div>
   
</div>


</div>

<!-- <div class="row" *ngIf="winwidth>800 && winwidth<1600" style="zoom: 66%;">
   
   <div class="col" style="max-height: 180vh; overflow-y: auto ; zoom: 80%;">
      <div class="row">
         <div class="column" *ngFor="let asset of assets; let i = index">
        
            <app-search-card [asset]="asset" ></app-search-card>  
             
           </div>
   
      </div>
      
   </div>
   
   
   </div> -->




