import { Component, OnInit, Input ,Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
@Component({
  selector: 'app-search-card',
  templateUrl: './search-card.component.html',
  styleUrls: ['./search-card.component.css']
})
export class SearchCardComponent implements OnInit {

  @Input() asset:any={};
  @Input() index: number;
  @Input() idx: string;
  @Output() selectedAsset:EventEmitter<number>;
  
  constructor(
  private router:Router) { 

      this.selectedAsset=new EventEmitter();

  }

  ngOnInit(): void {
  }

  Navigate()
  {
    this.router.navigate(["Assets",this.asset.clave]);
  }
}
