<app-navbar  [estructura]="estructura" [asset]="asset" ></app-navbar>
<!-- <app-navbar *ngIf="loaded && winwidth>800 && winwidth<1600" [estructura]="estructura" [asset]="asset" style="zoom: 66%;"></app-navbar> -->
<hr>
<!-- <button style="visibility: hidden" id="updateIMG" (click)="UpdateImage()"></button> -->


<br>
<div  *ngIf="loaded" class="row"  style="overflow: auto; text-align: center; width: auto; margin-left: 0%;zoom: 100%;">


    
    <div class="col"  style=" max-width: 100vw; max-height: 100vh;">

        <div class="row">
            <div class="col" style=" max-height: 40vh; max-width: 35%; overflow-y: auto; overflow-x: auto; zoom: 70%; align-items: center; ">
                <app-clickimg  *ngIf="sthide" [mapcoords]="imgcoords" [imgsource]="imgsource" [sensorref]="SensorUrl" [connection]="GTC"></app-clickimg>
            </div> 
            <div class="col"  style="max-height: 100%; max-width: 40%; overflow-y: auto;">
    
                <app-camaralive></app-camaralive>
                <!-- <app-videocam  [imgsource]="imgsource2" *ngIf="vdhide" ></app-videocam> -->
            </div>
            <div *ngIf="loaded" class="col" style="max-height: 100%; max-width: 25%;" >                   
                <!-- <app-mapimg  *ngIf="mphide" [imgsource]="sourcemap" ></app-mapimg> -->
                <app-mapadinamico></app-mapadinamico>
            </div>

        </div>
        
        <hr>
        <div class="row" style="overflow-x: auto; ">
            

            
                <!-- <app-graphimg style="max-height: 50vh; max-width: 50vw;" [imgsource]="imgsource3" *ngIf="grhide" ></app-graphimg> -->
                <div *ngIf="eventrue" class="col" style="max-width: 37.5%; zoom: 100%;"> <app-envolvente *ngIf="loaded"  [lineChartData]="envdata"  [titulo]="T1" [magnitud]='M1'></app-envolvente></div>
                <div *ngIf="eventrue" class="col" style="max-width: 37.5%; zoom: 100%;"><app-envolvente  *ngIf="loaded"  [lineChartData]="envdata2"  [titulo]="T2"  [magnitud]='M2'></app-envolvente></div>
                <div *ngIf="eventrue" class="col" style="max-width:25%">
                
                    <div class="card text-center" style="min-height: 36vh; ">
                        <div class="card-header" style="text-align: center;">
            
                        <div class="" style="zoom: 65%;"> 
                            
                                
                            <div class="row" style=" display: flex;justify-content: center; align-items: center;" >
                                <h4 >Últimos 5 Eventos</h4>
                            </div>
                            
            
                            <div class="row" >
                                <div class="form-group" >
            
                                    <input id="DatePickerEvent" name="dateOfBirth" 
                                            type="date" class="form-control"  />
                                            
                                    </div>
                                    <div class="form-group" >
                
                                    <input id="DatePickerEvent1" name="dateOfBirth1" 
                                            type="date" class="form-control"  />
                                            
                                    </div>
                
                                    <div class="col">
                                        <button type="button" class="btn btn-primary" (click)="Next_Ev()">
                                            Ir <i class="fas fa-arrow-alt-circle-right"></i>
                                        </button>
                                    </div>
            
                            </div>
                            
                            
                            
                                
                            
            
                        </div>
                            
                                
            
                            
                        
                        
                        </div>
            
                        
                        <div class="card-body"  style="overflow-y: auto; max-height: 100%; zoom: 65%;">
                            <app-data-table 
                            
                            (eventclicked)="ReloadGraphs($event)" 
                            [events]="events" 
                            [selected]="selectedId"
                            [activo]="Assetactive"
                            ></app-data-table>
                        </div>
                        <span *ngIf="!ev30d"> No hay eventos en los ultimos 30 días</span>
                        
                    </div>
            
                
                </div>
                <div *ngIf="!eventrue" class="alert alert-info" role="info" 
    style="font-size: 20px; font-weight: bold; max-width: 100%; display: block; margin-left: auto; margin-right: auto; text-align: center;">
     <h1 *ngIf="!loaded"> Cargando ...</h1>
     <h1 *ngIf="loaded"> No hay Eventos...</h1>
    </div>    

            
            

        </div>
        <br>


 
    </div>

            
</div>

<!-- <div class="row" *ngIf="loaded && winwidth>800 && winwidth<1600" style="overflow: auto; text-align: center; width: auto; margin-left: 0%;zoom: 100%;">


    
    <div class="col"  style=" max-width: 100vw; max-height: 100vh;">

        <div class="row">
            <div class="col" style=" max-height: 40vh; max-width: 35%; overflow-y: auto; overflow-x: auto; zoom: 70%; align-items: center; ">
                <app-clickimg  *ngIf="sthide" [mapcoords]="imgcoords" [imgsource]="imgsource" [sensorref]="SensorUrl" [connection]="GTC" style="zoom: 66%;"></app-clickimg>
            </div> 
            <div class="col"  style="max-height: 100%; max-width: 40%; overflow-y: auto;">
    
                <app-camaralive style="zoom: 66%;"></app-camaralive>
                
            </div>
            <div *ngIf="loaded" class="col" style="max-height: 100%; max-width: 25%;" >                   
                
                <app-mapadinamico style="zoom: 66%;"></app-mapadinamico>
            </div>

        </div>
        
        <hr>
        <div class="row" style="overflow-x: auto; ">
            

            
                
                <div *ngIf="eventrue" class="col" style="max-width: 37.5%; zoom: 100%;"> <app-envolvente *ngIf="loaded"  [lineChartData]="envdata"  [titulo]="T1" [magnitud]='M1'></app-envolvente></div>
                <div *ngIf="eventrue" class="col" style="max-width: 37.5%; zoom: 100%;"><app-envolvente  *ngIf="loaded"  [lineChartData]="envdata2"  [titulo]="T2"  [magnitud]='M2'></app-envolvente></div>
                <div *ngIf="eventrue" class="col" style="max-width:25%; zoom: 66%;">
                
                    <div class="card text-center" style="min-height: 36vh; ">
                        <div class="card-header" style="text-align: center;">
            
                        <div class="" style="zoom: 65%;"> 
                            
                                
                            <div class="row" style=" display: flex;justify-content: center; align-items: center;" >
                                <h4 >Últimos 5 Eventos</h4>
                            </div>
                            
            
                            <div class="row" >
                                <div class="form-group" >
            
                                    <input id="DatePickerEvent" name="dateOfBirth" 
                                            type="date" class="form-control"  />
                                            
                                    </div>
                                    <div class="form-group" >
                
                                    <input id="DatePickerEvent1" name="dateOfBirth1" 
                                            type="date" class="form-control"  />
                                            
                                    </div>
                
                                    <div class="col">
                                        <button type="button" class="btn btn-primary" (click)="Next_Ev()">
                                            Ir <i class="fas fa-arrow-alt-circle-right"></i>
                                        </button>
                                    </div>
            
                            </div>
                            
                            
                            
                                
                            
            
                        </div>
                            
                                
            
                            
                        
                        
                        </div>
            
                        
                        <div class="card-body"  style="overflow-y: auto; max-height: 100%; zoom: 65%;">
                            <app-data-table 
                            
                            (eventclicked)="ReloadGraphs($event)" 
                            [events]="events" 
                            [selected]="selectedId"
                            [activo]="Assetactive"
                            ></app-data-table>
                        </div>
                        <span *ngIf="!ev30d"> No hay eventos en los ultimos 30 días</span>
                        
                    </div>
            
                
                </div>
                <div *ngIf="!eventrue" class="alert alert-info" role="info" 
    style="font-size: 20px; font-weight: bold; max-width: 100%; display: block; margin-left: auto; margin-right: auto; text-align: center;">
     <h1 *ngIf="!loaded"> Cargando ...</h1>
     <h1 *ngIf="loaded"> No hay Eventos...</h1>
    </div>    

            
            

        </div>
        <br>


 
    </div>

            
</div> -->



    <div  class="" style=""> <button  type="button" class="btn btn-secondary" (click)="MainMenu()" style="background-color:rgb(110, 110, 110); min-width: 90vw; ">
        Volver a Activos <i class="fas fa-arrow-alt-circle-left"></i>
    </button></div>
    <!-- <div *ngIf="loaded && winwidth>800 && winwidth<1600" class="" style="zoom: 66%;"> <button  type="button" class="btn btn-secondary" (click)="MainMenu()" style="background-color:rgb(110, 110, 110); min-width: 90vw; ">
        Volver a Activos <i class="fas fa-arrow-alt-circle-left"></i>
    </button></div> -->




