<body style="background-color: rgb(255, 255, 255);">
  <div class="" style="margin-left: 5%; margin-right: 5%; ">
    
    <router-outlet  style="margin-left: 5%; margin-right: 5%; "></router-outlet>
      <div style="text-align: center;">
      </div>
    </div>
</body>


<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->


