// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    mapboxKey:"pk.eyJ1IjoiYmNvcnJlYTF1YyIsImEiOiJja3BmbDJjYTgwN3A0Mm5vMDFkdDAxZmRjIn0.udegjR2YqOaqM_ue4UYS7g",
    apiUrl:"https://gaz8aqqugj.execute-api.us-west-2.amazonaws.com/prod",// "http://127.0.0.1:8000",//
    urlList: [
      {
        id: 1,
        name: 'CV12',
        url: 'https://camaras.roc-dns.cl/camaras/cv12/stream',
        thumb: 'https://camaras.roc-dns.cl/camaras/cv12'
      },
      {
        id: 2,
        name: 'CV104',
        url: 'https://camaras.roc-dns.cl/camaras/cv104/stream',
        thumb: 'https://camaras.roc-dns.cl/camaras/cv104'
      },
      {
        id: 3,
        name: 'TRL2LS',
        url: 'https://camaras.roc-dns.cl/camaras/tripperls2/stream',
        thumb: 'https://camaras.roc-dns.cl/camaras/tripperls2'
      },
      {
        id: 4,
        name: 'SPLS2',
        url: 'https://camaras.roc-dns.cl/camaras/stockpilels2/stream',
        thumb: 'https://camaras.roc-dns.cl/camaras/stockpilels2'
      },
      {
        id: 5,
        name: 'CV8',
        url: 'https://camaras.roc-dns.cl/camaras/cv08/stream',
        thumb: 'https://camaras.roc-dns.cl/camaras/cv08'
      },
      {
        id: 6,
        name: 'SHL',
        url: 'https://camaras.roc-dns.cl/camaras/shiploader/stream',
        thumb: 'https://camaras.roc-dns.cl/camaras/shiploader'
      },
      {
        id: 7,
        name: 'CV5012',
        url: 'https://camaras.roc-dns.cl/camaras/cv5012/stream',
        thumb: 'https://camaras.roc-dns.cl/camaras/cv5012'
      },
      {
        id: 8,
        name: 'HC',
        url: 'https://camaras.roc-dns.cl/camaras/hidrociclon/stream',
        thumb: 'https://camaras.roc-dns.cl/camaras/hidrociclon'
      },
      {
        id: 9,
        name: 'CV5009',
        url: 'https://camaras.roc-dns.cl/camaras/cv5009/stream',
        thumb: 'https://camaras.roc-dns.cl/camaras/cv5009'
      },
      {
        id: 10,
        name: 'CV5010',
        url: 'https://camaras.roc-dns.cl/camaras/cv5010/stream',
        thumb: 'https://camaras.roc-dns.cl/camaras/cv5010'
      },
      {
        id: 11,
        name: 'CV239',
        url: 'https://camaras.roc-dns.cl/camaras/cv239/stream',
        thumb: 'https://camaras.roc-dns.cl/camaras/cv239'
      },
      {
        id: 12,
        name: 'CV238',
        url: 'https://camaras.roc-dns.cl/camaras/cv238/stream',
        thumb: 'https://camaras.roc-dns.cl/camaras/cv238'
      },
    ]

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
