<div class="center" style="text-align:center">
    <span class="badge badge-pill badge-light" style="min-width: 400px;" >
        <div class="row">

            <div class="col" width="25%"  *ngIf="estructura.stateGood == 'Active'" > 
                <h5><span class="badge badge-pill badge-primary animated blob pulse">
                    <i class="fas fa-check-circle"></i>                      
                </span></h5>
                
            </div>
            <div class="col" width="25%" *ngIf="estructura.stateGood == 'NActive'">
                <h5><span  class="badge badge-pill badge-primary ">
                    <i class="fas fa-check-circle"></i>                      
                </span></h5>
            </div>
            
            <div class="col" width="25%" *ngIf="estructura.stateErr == 'Error'">
                <h5><span  class="badge badge-pill badge-danger animated blob pulse">
                    <i class="fas fa-exclamation-triangle"></i>
                   </span></h5>
        
                
            </div>
            <div class="col" width="25%" *ngIf="estructura.stateErr == 'NError'" >
                <h5><span class="badge badge-pill badge-danger">
                    <i class="fas fa-exclamation-triangle"></i>
                   </span></h5>
            </div>
    
            <div class="col" width="25%" *ngIf="estructura.stateMant == 'Manteinance'">
                <h5><span  class="badge badge-pill badge-warning animated blob pulse">
                    <i class="fa fa-bolt"></i>
                    </span></h5>
                
            </div>
            <div class="col" width="25%" *ngIf="estructura.stateMant == 'NManteinance'"  >
                <h5><span class="badge badge-pill badge-warning">
                    <i class="fa fa-bolt"></i>
                    </span></h5>
            </div>
            
            <div class="col" width="25%" *ngIf="estructura.stateInac == 'Inactive'" >
                <h5><span  class="badge badge-pill badge-secondary animated blob pulse">
                    <i class="fas fa-spinner"></i>
                    </span></h5>
    
            
            </div>
            <div class="col" width="25%" *ngIf="estructura.stateInac == 'NInactive'" >
                <h5><span  class="badge badge-pill badge-secondary">
                    <i class="fas fa-spinner"></i>
                    </span></h5>
            </div>


        </div>
        

           

            
    </span>
</div>
