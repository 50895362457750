import { Component,OnInit } from '@angular/core';
// import { AwsService } from './services/aws.service';

// import { UserModel } from './models/user.model';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'spa';
  // usuario:UserModel;
  public estructura;
  constructor(
    // public auth:AwsService,
    )
  {}
  ngOnInit(): void {
    // this.usuario=new UserModel();
  }

  LoadParams(evento)
  {
    console.log("EVENTO",evento);
  if(evento)
  {
    this.estructura=evento[0];
    console.log("Evento OK!");
  }

  }
  onSubmit(f:NgForm)
  {
    
     const val = f.form.value;

        // if (val.username && val.pass) {
        //     this.authService.login(val.email, val.password)
        //         .subscribe(
        //             () => {
        //                 console.log("User is logged in");
        //                 this.router.navigateByUrl('/');
        //             }
        //         );
        // }

    // if(f.invalid){return;}
    // this.auth.Auth(this.usuario.email,this.usuario.password);


  }
}
