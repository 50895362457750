import { Component, OnInit ,Input} from '@angular/core';

@Component({
  selector: 'app-semaforoactivo',
  templateUrl: './semaforoactivo.component.html',
  styles: [
    './semaforoactivo.component.css'
  ]
})
export class SemaforoactivoComponent implements OnInit {

  constructor() { }
  @Input() estructura;

  ngOnInit(): void {
  }

  mouseOver(element)
  {
    document.getElementById("legend").innerHTML = element.title;

  }

}
