import { Component, OnInit , Input} from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styles: [
  ]
})
export class DropdownComponent implements OnInit {

  @Input() dataDrop;
  constructor() { }

  ngOnInit(): void {
  }
  buscarAsset(value)
  {
    console.log(value)
  }

}
