
      <div style="display: block; text-align: center;">
        <canvas baseChart 
                    [datasets]="lineChartData"
                    [labels]="lineChartLabels"
                    [options]="lineChartOptions"
                    [colors]="lineChartColors"
                    [legend]="lineChartLegend"
                    [chartType]="lineChartType"
      
                    (chartHover)="chartHovered($event)"
                    (chartClick)="chartClicked($event)"
                    style="min-height: 300px; min-width: 400px;"></canvas>
       <br> 
            
               
        </div>
    
