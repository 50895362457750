import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-semaforo',
  templateUrl: './semaforo.component.html',
  styles: [
  ]
})
export class SemaforoComponent implements OnInit {

  @Input() estructura;
  constructor() { }

  ngOnInit(): void {
  }

}
