import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-sensorform',
  templateUrl: './sensorform.component.html',
  styles: [
  ]
})
export class SensorformComponent implements OnInit {

  idType="ACC";
  idStruct="NON";
  PreID=this.idType+"-"+this.idStruct;
  assets=[];
  constructor() { }

  ngOnInit(): void {

    // this.Aservice.getProperties().then(res=>
    //   {

          
    //       var temp=[];
    //       res.Items.forEach((element,index) => {
    //           temp.push({nombre:element.NameA, acelerometros:element.Accels,straing:element.Strain,Fecha:element.dateinit
    //               ,clave:element.Id,img:element.ImgUrl,idx:index,distanciometer: element.Distance})
    //       });
    //       console.log("Activos",temp);
    //       this.assets=temp;
          

    //   });;
  }

 Type()
  {
    var temp:any=document.getElementById("inputType");
    this.idType=(temp.options[temp.selectedIndex].value.substring(0,3));
    var temp2=this.idType;
    console.log("Test",temp2);
    this.PreID=this.idType+"-"+this.idStruct;
  }
  Structure()
  {
    var temp:any=document.getElementById("inputStruct");
    this.idStruct=(temp.options[temp.selectedIndex].value.split("-")[0]);
    var temp2=this.idStruct;
    console.log("Test",temp2);
    this.PreID=this.idType+"-"+this.idStruct;

  }
  Submit()
  {
    var boolean=true;
    var structure=this.idStruct;
    if(structure=="NON")
    {
      boolean=false;
    }
    
    var preid=this.PreID
    var id:any=document.getElementById("IDInput");
    
    if(id=="")
    {
      id="0";
    }
    
    var fullid=preid+"-"+id.value;
    var tempx:any=document.getElementById("inputType");
    var type=(tempx.options[tempx.selectedIndex].value);
    var descr:any=document.getElementById("DescriptionInput");
    var description:any=descr.value+" ";
    
    if(description=="")
    {
      boolean=false;
    }
    
    var temp:any=document.getElementById("inputState");
    var state=temp.options[temp.selectedIndex].value;
    var lc="None";
    var sensor={'Structure':{S: structure},'Id':{S: fullid},'TypeS':{S: type},
    'Description':{S: description},'StateS':{S: state},'LC':{S: lc}}
    console.log(sensor)
    
    var params={
      TableName:"Sensores",
      Item:sensor

    }
    
    if(boolean){

       //this.AWSserv.PutItem(params);
    }
    else
    {
      console.warn("Invalid Values")
    }
    

  }
}
