import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rmimg',
  templateUrl: './rmimg.component.html',
  styleUrls: ['./rmimg.component.css']
})
export class RmimgComponent implements OnInit {

  @Input() imgsource="https://s3.us-west-2.amazonaws.com/roc.sgs.rmimages/RM_ROC-201_09_Nov_2021_15_33_19_0090_Verde_None.png"
  constructor() { }

  ngOnInit() {
  }

}
