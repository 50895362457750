<app-navbar  [estructura]="estructura" [asset]="asset" [selected]="selected"></app-navbar>
<!-- <app-navbar *ngIf="hayeventos && winwidth>800 && winwidth<1600" [estructura]="estructura" [asset]="asset" [selected]="selected" style="zoom: 66%;"></app-navbar> -->

<div  style="max-height:max-content; max-width: max-content;">

  <!-- <div style="text-align: center;">
    <div class="col" width="25%"  *ngIf="state == 'Azul'" > 
        <h2>Evento <span class="badge badge-pill badge-primary animated blob pulse"> <i class="fa fa-circle"></i></span></h2></div>
  
    <div class="col" width="25%" *ngIf="state == 'Verde'">
        <h2>Evento <span  class="badge badge-pill badge-success animated blob pulse"><i class="fa fa-circle"></i> </span></h2></div>
    
  
    <div class="col" width="25%" *ngIf="state == 'Amarillo'">
        <h2>Evento  <span class="badge badge-pill badge-warning animated blob pulse"><i class="fa fa-circle"></i> </span></h2></div>
    
    
    <div class="col" width="25%" *ngIf="state == 'Rojo'" >
        <h2>Evento  <span  class="badge badge-pill badge-danger animated blob pulse"><i class="fa fa-circle"></i></span></h2></div>

           
    <div class="col" width="25%" *ngIf="state == 'Check'" >
      <h2>Evento  <span  class="badge badge-pill badge-dark animated blob pulse"><i class="fa fa-circle"></i></span></h2></div>

</div>

<div class="dropdown" *ngIf="datasubs == 'AC'" >
    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id='dropdownsel'>
      X
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"  >
      <span class="dropdown-item" *ngFor="let item of ['X','Y','Z']; let i = index" >
        <button  (click)="next(item)"  class="btn btn-outline-secondary btn-block" style="width: 100px;" >
          {{item}}</button></span>
      
    </div>
  </div>
  <div class="dropdown" *ngIf="datasubs == 'ST'">
    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      A
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"  >
      <span class="dropdown-item" *ngFor="let item of ['A','B','C']; let i = index" >
        <button  (click)="next(item)"  class="btn btn-outline-secondary btn-block" style="width: 100px;" >
          {{item}}</button></span>
      
    </div>
  </div> -->
  <hr>

  <div class="row">

<div class="col">
  <button (click)="NavigatePrev()"
  class="btn btn-dark btn-block" style="background-color:rgb(110, 110, 110)"  #AxB>Volver a {{previous}}</button>
  
</div>
<div class="col">
  <a href="{{videolink}}" target="_blank" style="text-decoration:none;">
    <button 
    class="btn btn-dark btn-block" style="background-color:rgb(110, 110, 110)" >Descargar Video <i class="fas fa-download"></i></button>
  </a>
  
    
</div>
  </div>
 
    <hr>
    
    <div *ngIf="!hayeventos" class="alert alert-info" role="info" 
    style="font-size: 20px; font-weight: bold; max-width: 100%; display: block; margin-left: auto; margin-right: auto; text-align: center;">
     <h1 *ngIf="cargando"> Cargando ...</h1>
     <h1 *ngIf="!cargando"> No hay Eventos...</h1>
    </div>    
     
   

<div class="row">
  
<div class="col" style="width: 35%;">
  
  <app-radarbar  *ngIf="!cargando" [radarChartLabels]="labelRadarMean" [data]="dataRadarMean"></app-radarbar>
</div>

 <div class="col" style="width: 35%; zoom: 100%;">
   <app-radarbar *ngIf="!cargando" [radarChartLabels]="labelRadarPeaks" [data]="dataRadarPeaks"></app-radarbar>
</div>
<div class="col" style="width: 15%;"> <app-envolvente *ngIf="!cargando" [lineChartData]="envdata"  [titulo]="T1" [magnitud]='M1'></app-envolvente></div>
  <div class="col" style="width: 15%;"><app-envolvente *ngIf="!cargando" [lineChartData]="envdata2"  [titulo]="T2"  [magnitud]='M2'></app-envolvente></div>
  

</div>
<hr>
<div class="row">

  <div class="col" style="width: 40%; zoom: 100%;">
    <app-specline *ngIf="!cargando"  [lineChartData]="specdata" [lineChartLabels]="speclabel" [specfrecs]="specfrecs" ></app-specline>
  </div>
  <div class="col" style="width: 30%; " >

    <app-rmimg  *ngIf="!cargando" [imgsource]="rmurl"></app-rmimg>
  
      </div>
  <div class="col" style="width: 40%; zoom: 100%;">
    <app-chartbar *ngIf="!cargando"  id="HistogramaAcc" [sensores]="labelRadarMean" [database] ="databar" [datachart]="Histograma" [dataranges]="HRanges" [histonchange]="histonchange"></app-chartbar>
  </div>
  
</div>
   


<hr>

    


</div>

<!-- <div *ngIf="hayeventos && winwidth>800 && winwidth<1600" style="max-height:max-content; max-width: max-content;">
  <hr>

  <div class="row" style="zoom: 66%;">

<div class="col">
  <button (click)="NavigatePrev()"
  class="btn btn-dark btn-block" style="background-color:rgb(110, 110, 110)"  #AxB>Volver a {{previous}}</button>
  
</div>
<div class="col">
  <a href="{{videolink}}" target="_blank" style="text-decoration:none;">
    <button 
    class="btn btn-dark btn-block" style="background-color:rgb(110, 110, 110)" >Descargar Video <i class="fas fa-download"></i></button>
  </a>
  
    
</div>



  </div>
 
    <hr>
    
    <div *ngIf="!hayeventos" class="alert alert-info" role="info" 
    style="font-size: 20px; font-weight: bold; max-width: 100%; display: block; margin-left: auto; margin-right: auto; text-align: center;">
     <h1 *ngIf="cargando"> Cargando ...</h1>
     <h1 *ngIf="!cargando"> No hay Eventos...</h1>
    </div>    
     
   

<div class="row">
  
<div class="col" style="width: 35%;">
  
  <app-radarbar  *ngIf="!cargando" [radarChartLabels]="labelRadarMean" [data]="dataRadarMean"></app-radarbar>
</div>

 <div class="col" style="width: 35%; zoom: 100%;">
   <app-radarbar *ngIf="!cargando" [radarChartLabels]="labelRadarPeaks" [data]="dataRadarPeaks"></app-radarbar>
</div>
<div class="col" style="width: 30%; " >
  <app-rmimg  *ngIf="!cargando" [imgsource]="rmurl"></app-rmimg>
    </div>

  

</div>
<hr>
<div class="row">
  <div class="col" style="width: 15%;"> <app-envolvente *ngIf="!cargando" [lineChartData]="envdata"  [titulo]="T1" [magnitud]='M1'></app-envolvente></div>
  <div class="col" style="width: 15%;"><app-envolvente *ngIf="!cargando" [lineChartData]="envdata2"  [titulo]="T2"  [magnitud]='M2'></app-envolvente></div>
</div>
<hr>
<div class="row">

  <div class="col" style="width: 40%; zoom: 100%;">
    <app-specline *ngIf="!cargando"  [lineChartData]="specdata" [lineChartLabels]="speclabel" [specfrecs]="specfrecs" ></app-specline>
  </div>

  <div class="col" style="width: 40%; zoom: 100%;">
    <app-chartbar *ngIf="!cargando"  id="HistogramaAcc" [sensores]="labelRadarMean" [database] ="databar" [datachart]="Histograma" [dataranges]="HRanges" [histonchange]="histonchange"></app-chartbar>
  </div>
  
</div>
   


<hr>

    


</div> -->