import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { HomeComponent} from './components/home/home.component';
import { ActivosComponent} from './components/activos/activos.component';
import { SectoresComponent} from './components/sectores/sectores.component';
import { SensoresComponent } from './components/sensores/sensores.component';
import { SearchComponent } from './components/search/search.component';
import { EventoComponent } from './components/shared/evento/evento.component';
import { SensorpageComponent } from './components/shared/sensorpage/sensorpage.component';
import { SensorformComponent } from './components/shared/sensorform/sensorform.component';
import { LoginComponent } from './login';
import { AuthGuard } from './_helpers';
import { CamaraliveComponent } from './components/camaralive/camaralive.component';

const routes: Routes = [
    { path: '', component: ActivosComponent , canActivate: [AuthGuard]},
    { path: 'login', component: LoginComponent },
    { path: 'Assets', component: ActivosComponent , canActivate: [AuthGuard]},
    { path: 'Assets/:id', component: SensoresComponent , canActivate: [AuthGuard]},
    { path: 'Event/:id/:ev', component: SensorpageComponent , canActivate: [AuthGuard]},
    { path: 'NewSensor', component: SensorformComponent , canActivate: [AuthGuard]},
    { path: 'Assets/:id/camara', component: CamaraliveComponent , canActivate: [AuthGuard]},
    { path: '**', component: ActivosComponent, canActivate: [AuthGuard]},
    
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class APP_ROUTING {}
