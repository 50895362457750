import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-camaravideoactiva',
  templateUrl: './camaravideoactiva.component.html',
  styleUrls: ['./camaravideoactiva.component.css']
})
export class CamaravideoactivaComponent implements OnInit {

  

  devices = []
  
  constructor(
    private titleService: Title,
    private router: Router
  ) {

    this.devices = environment.urlList;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Demo');
  }

  goTo() {
    this.router.navigate([this.router.url+ "/camara"])
  }


}
