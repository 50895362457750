
    <div class="card assetcard" >
      <button (click)="Navigate()" class="btn btn-outline-secondary btn-block"  >
        <img src="assets\images\{{asset.img}}"  class="card-img-top"  alt="...">
      </button>
      <!-- <img (click)="Navigate()" src="assets\images\{{asset.img}}" class="card-img-top" alt="..."> -->
      <div class="card-body">
        <h5 class="card-title" style="text-align: center;">{{asset.nombre}}</h5>
        <table class="table" style="text-align: center;font-weight: bold;">
          <thead>
            <th>ACC</th>
            <th>SG</th>
            <th>DIST</th>
            
          </thead>
          <tbody>
            <td>{{asset.acelerometros}}</td>
            <td>{{asset.straing}}</td>
            <td>{{asset.distanciometer}}</td>
            
          </tbody>
        </table>
        <hr>
        <app-semaforoactivo style="max-width: 15vw;" app-semaforo class="btn-block" [estructura]="{state: asset.state}" ></app-semaforoactivo>
        <!-- <app-semaforo class="btn-block" [estructura]="estructura"></app-semaforo> -->
      <hr>
        <!-- <p class="card-text"><small class="text-muted">monitoreado desde: {{asset.Fecha}}</small></p> -->
        <button (click)="Navigate()" class="btn btn-outline-primary btn-block">Ver Más</button>
      </div>
    </div>
 

<!-- <div class="card animated fadeIn fast" >
    <img (click)="Navigate()" src="assets\images\{{asset.img}}" class="card-img-top" alt="...">
    <div class="card-body">
      
        <h5 class="card-title">{{asset.nombre}}</h5>
        
          <p class="card-text">{{asset.acelerometros}} accelerometers {{asset.straing}} strain gauges</p>
       <hr>
        <app-semaforo class="btn-block" [estructura]="estructura"></app-semaforo>
      <hr>
        <p class="card-text"><small class="text-muted">since {{asset.Fecha}}</small></p>
      
      
    
    <button (click)="Navigate()" class="btn btn-outline-primary btn-block">See More</button>
    
    </div>
  </div>  -->
