<div  style="max-width: auto; " fxLayout="row" fxLayoutAlign="center none">



  <div fxFlex="95%" fxLayout="column" >
      <!-- <h1 style="color: #469aa5">Cámara {{cameraName}}</h1> -->

      <div fxFlex="100" [ngStyle]="{'display': !loadCameraError ? 'block' : 'none' }" >
          <div style="max-height: 35vh; max-width: 0px;" [ngStyle]="{'background': !played ? '#000000' : '#cacaca'}">
              <img *ngIf='!played' [ngStyle]="{'cursor': !played ? 'pointer' : 'default' }"
              (click)="play()" src="../../assets/images/play.png" style="position: absolute; width: 100px; margin-left: 270px; margin-top: 110px;" />
              <video [poster]="thumb" [controls]='controls' name="videoElement" id="videoElement"
                  style="width: 640px; height: 480px; max-height: 35vh;" [ngStyle]="{'cursor': !played ? 'pointer' : 'default' }"
                  (click)="play()">
              </video>
          </div>

      </div>

      <div fxFlex="100" [ngStyle]="{'display': loadCameraError ? 'block' : 'none' }">
          <img src="../../assets/images/no-camera.jpg" style="width: 640px;" />
      </div>

  </div>
</div>
