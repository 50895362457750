import { Component, OnInit ,Output, EventEmitter} from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label,Color } from 'ng2-charts';
import { Key, element } from 'protractor';
import { splitAtColon } from '@angular/compiler/src/util';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-sensores',
  templateUrl: './sensores.component.html'
})
export class SensoresComponent implements OnInit {

  sourcemap="./assets/images/test.png"
  ev30d=false
  asset:any={};
  winwidth=window.innerWidth
  nota:any='0'
  imgsource="./assets/images/test.png";
  sthide=true
  mphide=true
  vdhide=true
  grhide=true
  imgsource2="./assets/images/test.png";
  imgsource3="./assets/images/test.png";
  public pageid=0;
  loggedin:boolean;
  datareadyEvents=false;
  imgcoords=["0,0,0"]
  histonchange=false;
  labelRadarAccel= ['AX1', 'AY1', 'AZ1'];
  estructura={state:"Active"};
  selected={};
  initialize=false;
  Escalabool=false
  public eventrue=false;
  public selectedId:null;
  public events=[];
  public datam;
  public Histograma:ChartDataSets[]=[{data:[],label:""}];
  public HRanges:any[];
  public data;
  public databar:any = {datachart:[[]],datalabel:[]};
  public databarloaded=false;
  public loaded=false;
  public sensores=[];
  public wn ={state:"Minor"}
  @Output() onLoadP:EventEmitter<any>=new EventEmitter<any>();
  public SensorUrl="Sensor/"
  //Specs
  public speclabel=[];
  public specdata=[];
  specfrecs=[]
  EstadoS=["-","-"]
  //Mean
  public dataRadarMean=[]
  labelRadarMean= [];
  LEK=[""]
  LEKidx=0
  LastEvKey=""
  PreviowsLEK=""
  //Peaks
  public dataRadarPeaks=[]
  labelRadarPeaks= [];
  
  Wns=[]
  Wne=[]
  WnR=[]
  GTC:any=0
  eventos=[]
  Estado=""
  Assetactive=this.activatedRoute.params["_value"].id

  //Graficos env
  T1="Aceleraciones"
  T2="Deformaciones"
  M1="Magnitud [mg]"
  M2="Magnitud [ue]"
  envdata=[]
  envdata2=[]
  puntosenvacc=[]
  puntosenvsg=[]
  puntosenvdis=[]
  dataenv=[[],[],[],[]]






  

  constructor(
    private activatedRoute:ActivatedRoute,
    private http: HttpClient,
    private router:Router
    ) {

      // Trae la informaciónd el Backend 
      
      var clave = this.activatedRoute.params["_value"].id
      console.log("Clave",clave)
      
      // this.http.get(`${environment.apiUrl}/api/gtc/${clave}`).subscribe(res => 
      //   { 
      //       console.log('GTC: ',res)
      //       this.GTC=res
            
      //   })


  
    
    

      this.http.get(`${environment.apiUrl}/api/activos/${clave}`).subscribe(res => 
      { 
          this.asset = res
          console.log("AssetP",JSON.stringify(res))
          localStorage.setItem("asset",JSON.stringify(res))
          console.log("width",this.winwidth)
          
          this.asset.Wn.forEach((element,i) => {
            this.asset.Wn[i]=element.map(Number)
          }); 
          this.Wns=this.asset.Wn  
          this.imgsource = "./assets/images/"+this.asset.clave+"img.BMP";
          this.imgsource2 = "https://s3.us-west-2.amazonaws.com/roc.sgs.rmimages/camaraactiva"+this.asset.clave+".png?" + new Date().getTime();
          
          
          // setInterval(function () {
            
          //   document.getElementById("updateIMG").click()
          
          // }, 50000);

          this.sourcemap= "./assets/images/"+(this.asset.clave as string).toLowerCase()+"map.png";
          console.log(this.sourcemap)
          this.imgcoords = this.asset.coords;
          this.estructura = {state:res["state"]}
          localStorage.setItem("estructura",JSON.stringify(this.estructura))
          //console.log("Wn:",this.Wns)
      })

      // this.http.get(`${environment.apiUrl}/api/notes/${clave}/`).subscribe(res => 
      //   { 
      //       this.nota = res[0]
      //       this.EstadoS = res[1]
      //       this.EstadoS[0]=this.EstadoS[0].split(' veces')[0]
      //       this.EstadoS[1]=this.EstadoS[1].split(' eventos')[0]
      //       console.log('Nota',res)
      //       this.loaded=true
      //   })
         
        

        this.GetEvents()
   }

   UpdateImage()
   {
     //console.log("Image Update")
    this.imgsource2 = "https://s3.us-west-2.amazonaws.com/roc.sgs.rmimages/camaraactiva"+this.asset.clave+".png?" + new Date().getTime();

   }
   GetEvents(){
    var yourDate = new Date();  // for example
    var yourDate1 = new Date();  

    // the number of .net ticks at the unix epoch
    var epochTicks = 621355968000000000;

    // there are 10000 .net ticks per millisecond
    var ticksPerMillisecond = 10000;

    // calculate the total number of .net ticks for your date
    var yourTicks = (yourDate.getTime() * ticksPerMillisecond)/10000000-2000*24*60*60;
    var yourTicks2 = (yourDate1.getTime() * ticksPerMillisecond)/10000000;
  
    //console.log(elementodp) 
    var clavetick=parseInt(yourTicks as any).toString()+"_"+parseInt(yourTicks2 as any).toString()//16261344000000000
    console.log("Clave23",clavetick)

    var clave=this.activatedRoute.params["_value"].id+"_"+clavetick

    this.http.get(`${environment.apiUrl}/api/events/${clave}`).subscribe(res => 
      {
       try{
        this.selected = res["Items"][0]
        this.events = res["Items"]
        console.log("Events",res)
        this.data=this.events
        this.imgsource3=this.selected["IMurl"]
 
        if(this.selected['Envolvente']){ 
          this.dataenv[0]=this.selected['Envolvente'][0]['AccMax']
          this.dataenv[1]=this.selected['Envolvente'][1]['AccMin']
          this.dataenv[2]=this.selected['Envolvente'][2]['SgMax']
          this.dataenv[3]=this.selected['Envolvente'][3]['SgMin']
         console.log("Envolvente",this.dataenv)
         }
         if(this.selected['PuntosEnv']){
  
          this.puntosenvacc=this.selected['PuntosEnv'][0]
          this.puntosenvsg=this.selected['PuntosEnv'][1]
          this.puntosenvdis=this.selected['PuntosEnv'][2]
  
         }



        try {
        this.selectedId= this.selected["ID"]
        console.log("Selected",this.selected)
       }
       catch (error) {
         console.warn(error);
       }
 
       this.SensorUrl="Sensor/"+clave
       this.loaded = true
        
       try{
         this.populate()
         }
         catch(error){
           console.warn('Err',error);
         }

       }
       catch(error){
        console.warn('Err',error);
       }
      
      this.ev30d=true
      this.loaded=true




   })



    
 

  }
   

  MainMenu()
  {
    this.router.navigate(["Assets"]);
    
  }

   Hide(valor){

if(valor==0)
    this.sthide=!this.sthide
if(valor==1)
    this.mphide=!this.mphide
if (valor==2)
    this.vdhide=!this.vdhide
if (valor==3)
    this.grhide=!this.grhide

   }

   Ver(){
     this.Escalabool=!this.Escalabool
   }

   Prev_Ev()
   {

    console.log("Next",this.LEK[this.LEKidx])
    if(this.LEKidx>1){
      var clave = this.activatedRoute.params["_value"].id+"X"+this.LEK[this.LEKidx-2]
      this.http.get(`${environment.apiUrl}/api/events/${clave}`).subscribe(res => 
        {
          console.log("Next", res, this.LEK)
          this.selected = res["Items"][0]
          this.LastEvKey=res['LastEvaluatedKey'].ID
             
          if(this.LEKidx>1){this.LEKidx-=1}
          
         this.events = res["Items"]
         console.log("Events",res)
         this.data=this.events
         try {
         this.selectedId= this.selected["ID"]
         console.log("Selected",this.selected)
        }
        catch (error) {
          console.warn(error);
          // expected output: ReferenceError: nonExistentFunction is not defined
          // Note - error messages will vary depending on browser
        }
        try{

        this.initialize=false
        
        this.populate()
        }
        catch(error){
          console.warn(error);
        }
        })
    }
    
   }

   Next_Ev()
   {
    var elementodp = (document.getElementById('DatePickerEvent') as any).value;
    var elementodp2 = (document.getElementById('DatePickerEvent1') as any).value;
    this.Estado='Se muestran eventos entre ' + elementodp + ' y ' +elementodp2
    console.log(this.Estado)
    this.eventos=[]
    //elementodp=elementodp.split('-')

    var yourDate = new Date(elementodp);  // for example
    var yourDate1 = new Date(elementodp2);  

    // the number of .net ticks at the unix epoch
    var epochTicks = 621355968000000000;

    // there are 10000 .net ticks per millisecond
    var ticksPerMillisecond = 10000;

    // calculate the total number of .net ticks for your date
    var yourTicks = (yourDate.getTime() * ticksPerMillisecond)/10000000;
    var yourTicks2 = (yourDate1.getTime() * ticksPerMillisecond)/10000000;
    //console.log(yourTicks)

    localStorage.setItem('eventget',elementodp)
    localStorage.setItem('eventget2',elementodp2)
    //console.log(elementodp) 
    var clavetick=yourTicks.toString()+"_"+yourTicks2.toString()//16261344000000000
    var clave=this.activatedRoute.params["_value"].id+"_"+clavetick
    console.log("Clave22",clave)




    
    var clave=this.activatedRoute.params["_value"].id+"_"+clavetick
    this.http.get(`${environment.apiUrl}/api/events/${clave}`).subscribe(res => 
      {
       
        this.selected = res["Items"][0]
        //this.LastEvKey=res['LastEvaluatedKey'].ID   
        //this.LEK.push(res['LastEvaluatedKey'].ID)
        //this.LEKidx+=1
       this.events = res["Items"]
       console.log("Events",res)
       this.data=this.events
       try {
       this.selectedId= this.selected["ID"]
       console.log("Selected",this.selected)
      }
      catch (error) {
        console.warn(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      try{
      this.populate()
      }
      catch(error){
        console.warn('Err',error);
      }
      this.SensorUrl="Sensor/"+clave
      this.loaded = true
       
      
       
      })

   }

   populate()
     {
        // Toma la información que se obtuvo del backend y la transforma en los formatos requeridos por los gráficos
        






        var selected = this.selected
      //Graph

  


      this.envdata=[
      ]
  
      for (var i = 0; i < this.puntosenvacc.length; i++) {
  this.envdata.push({data:[{y:this.puntosenvacc[i][2],x:this.puntosenvacc[i][1]}],label:this.puntosenvacc[i][0],
    backgroundColor: 'rgba(120,200,200,0)',
    borderColor: 'rgba(150,200,200,1)',
    pointBackgroundColor: 'rgba(250,50,50,1)',
    pointBorderColor: 'rgba(200,50,50,1)',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(77,83,96,1)'
  
  })
  
      }
  
      this.envdata.push({ data: this.dataenv[0], label: 'AccMax' ,
      backgroundColor: 'rgba(255,200,100,0.5)',
      borderColor: 'rgba(255,200,100,1)',
      pointBackgroundColor: 'rgba(200,200,255,0)',
      pointBorderColor: 'rgba(10,10,50,0)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'},)
      this.envdata.push({ data: this.dataenv[1], label: 'AccMin',
      backgroundColor: 'rgba(255,200,100,0.5)',
      borderColor: 'rgba(255,200,100,1)',
      pointBackgroundColor: 'rgba(200,200,255,0)',
      pointBorderColor: 'rgba(10,10,50,0)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)' },)
  
      
      this.envdata2=[
      ]
  
      for (var i = 0; i < this.puntosenvsg.length; i++) {
        this.envdata2.push({data:[{y:this.puntosenvsg[i][2],x:this.puntosenvsg[i][1]}],label:this.puntosenvsg[i][0],
          backgroundColor: 'rgba(120,200,200,0)',
          borderColor: 'rgba(150,200,200,1)',
          pointBackgroundColor: 'rgba(250,50,50,1)',
          pointBorderColor: 'rgba(200,50,50,1)',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(77,83,96,1)'
        })}
  
        this.envdata2.push({ data: this.dataenv[2], label: 'DefMax',
        backgroundColor: 'rgba(255,200,100,0.5)',
        borderColor: 'rgba(255,200,100,1)',
        pointBackgroundColor: 'rgba(200,200,255,0)',
        pointBorderColor: 'rgba(10,10,50,0)',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(77,83,96,1)' })
  
        this.envdata2.push({ data: this.dataenv[3], label: 'DefMin' ,
        backgroundColor: 'rgba(255,200,100,0.5)',
        borderColor: 'rgba(255,200,100,1)',
        pointBackgroundColor: 'rgba(200,200,255,0)',
        pointBorderColor: 'rgba(10,10,50,0)',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(77,83,96,1)'})

//End


        var events = this.events
        // this.databar={datachart:[[],[]],dataranges:[]}
        // this.specdata=[
        //   { data: [], label: 'Sx1' },
        //   { data: [], label: 'Sy1' }, 
        //   { data: [], label: 'Sz1' },
        //   { data: [], label: 'Sx2' },
        //   { data: [], label: 'Sy2' }, 
        //   { data: [], label: 'Sz2' },
        //   { data: [], label: 'Sx3' },
        //   { data: [], label: 'Sy3' }, 
        //   { data: [], label: 'Sz3' },
        //   { data: [], label: 'Sx4' },
        //   { data: [], label: 'Sy4' }, 
        //   { data: [], label: 'Sz4' },
        // ]
        events.map(function(el){el["Tinit"] = new Date(el["Tinit"]*1000+60*60*4000)})

        console.log("Selected",this.selected)
        // selected["Histograma"][0]["Quant"].forEach(element=> {this.databar.datachart[0].push({data:this.stringArrayToFLoat(element), label: 'Histograma de Datos (Último Evento)' })})
        // selected["Histograma"][2]["Hist"].forEach(element=> { this.databar.datachart[1].push({data:this.stringArrayToFLoat(element), label: 'Histograma de Datos (Histórico)' })});
        // console.log('C1')
        // this.databar.dataranges=selected["Histograma"][1]["Ranges"][1]
        
        // this.histonchange=!this.histonchange;

         
        // this.specfrecs=[]
        // var speclen=Math.min(selected["Espectros"].length,12)
        // console.log('CX:',speclen)
        // this.Wne=selected["Frecuencias_Naturales"]

      //   this.Wne.forEach((element,i) => {
      //     this.Wne[i]=element.map(Number)
      //   }); 

      //   console.log('Wn:',this.Wne,this.Wns)

      //  var WnR=[]
      //  for (var i = 0; i < this.Wns.length; i++)
      //  {
      //   var value=1000000000000000000000000000000;
      //   var valueant:any=[]
      //     for(var j = 0; j < this.Wns[i].length; j++)
      //     {
      //       var value=10000000000000000000000000000;
      //       for(var h = 0; h < this.Wne[i].length; h++)
      //       {
              
      //           var value2=Math.abs(this.Wns[i][j]-this.Wne[i][h])
                
      //           //console.log('Value2: ',value2)
                
      //           if (value2 < value)
      //           {
      //             //console.log('Value2: ',value2)
      //             value=value2
                  
      //           }


      //       }
      //       valueant.push(value)
            
            

      //     }
          
      //     var valuefinal=Math.max(...valueant)
      //     console.log('C2')
      //     console.log("Value: ",valueant)
      //     if(value<0.05)
      //           WnR.push('Active')
      //     else if(value>=0.05 && value <1)
      //           WnR.push('Minor')
      //     else if(value>=1 && value <2)  
      //           WnR.push('Maintenance')
      //     else  
      //           WnR.push('Error')
          
      //  }
      //  console.log('WnR',WnR)
      //   this.WnR=WnR
      //   console.log('C3:',speclen)
      //   for (var i = 0; i < speclen; i++) {
      //      this.specdata[i].data=selected["Espectros"][i];
      //      this.specfrecs.push({label:this.specdata[i].label,data: selected["Frecuencias_Naturales"][i]})
      //   }
      //   console.log('C4')
      //   this.specdata = this.specdata.slice(0,speclen)
      //   this.speclabel=[]//this.data[0].Espectros[0][1];


        //this.fillRadarData(selected, events)
        this.eventrue=true;
        this.loaded = true
        this.initialize=true
       
      }

  fillRadarData(selected, events)
  {
    
    
    this.labelRadarMean= [];
    this.labelRadarPeaks= [];

    var rango = []
    for (var i = 0; i < selected.PGA[0].length; i++) { rango.push(i)}

    var media_historico = rango.map(function(index){ return events.reduce(function (suma, el) {  return suma + parseFloat(el.PGA[1][index]) / events.length;}, 0);})
    var peak_historico = rango.map(function(index){ return events.reduce(function (suma, el) {  return suma + parseFloat(el.PGA[0][index]) / events.length;}, 0);})

    var media_referencia = rango.map(function(index){ return 1})
    var peak_referencia = rango.map(function(index){ return 1})

    var media_selected = selected.PGA[1].map(function(el){return parseFloat(el)})
    var peak_selected = selected.PGA[0].map(function(el){return parseFloat(el)})


    peak_referencia = peak_referencia.map(function(each_element){
      return Number(each_element.toFixed(2));
  });
  peak_selected = peak_selected.map(function(each_element){
    return Number(each_element.toFixed(2));
});
peak_historico = peak_historico.map(function(each_element){
  return Number(each_element.toFixed(2));
});
media_referencia = media_referencia.map(function(each_element){
  return Number(each_element.toFixed(2));
});
media_selected = media_selected.map(function(each_element){
  return Number(each_element.toFixed(2));
});
media_historico = media_historico.map(function(each_element){
  return Number(each_element.toFixed(2));
});

    this.dataRadarPeaks=[
      { data: peak_referencia, label: 'Referencia' },
      { data: peak_selected, label: 'Evento seleccionado' },  
      { data: peak_historico, label: 'Ultimos 10 Eventos' },    
    ]

    this.dataRadarMean=[
      { data: media_referencia, label: 'Referencia' },
      { data: media_selected, label: 'Evento seleccionado' }, 
      { data: media_historico, label: 'Ultimos 10 Eventos' },   
      ]


    //Labels
    selected.Sensor.forEach(element=> {
      if(element.substring(0,2)=="AC")
      {
        ['X' ,'Y', 'Z'].forEach(indice=>
        {
          this.labelRadarMean.push(`${element}-${indice}`);  
          this.labelRadarPeaks.push(`${element}-${indice}`);
        })
      }
      else
      {
        this.labelRadarMean.push(element)  
        this.labelRadarPeaks.push(element)
      }
    });

  }

  stringArrayToFLoat(array)
  // Función de apoyo, ya que algunos arreglos vienen con los datos en string y se pasan a float.
  {
    return array.map(function(el){return parseFloat(el).toFixed(2)})
  }

  RefreshFRS(index)
  {
    //debugger
    
    var speclen=Math.min(this.data[index].Espectros.length,12)
    //debugger
    for (var i = 0; i < speclen; i++) {
    var dataspec=[]
     this.specdata[i].data=this.data[index].Espectros[i];
     this.specfrecs.push({label:this.specdata[i].label,data: this.data[index].Frecuencias_Naturales[i]})
    }
    //debugger
    for(var i = speclen; i < 12; i++)
    {
       this.specdata.pop() 
    }
  }
  
  ReloadGraphs(id)
  {
    console.log("datachange")
    if (this.data)
    {
      this.selected=this.events[id-1]
      this.populate()
      // console.log("DataChange", this.data)
      // this.databar.datachart[0]=[];
      // this.data[id-1]["Histograma"][0]["Quant"].forEach(element=>
      //   {this.databar.datachart[0].push({data:element,label: 'Histograma de Datos (Último Evento)' })});
      
      // this.databar.dataranges=this.data[id-1]["Histograma"][1]["Ranges"][1];
      // this.histonchange=!this.histonchange;

      // this.fillRadarData(this.data[id-1], this.data)

      // this.specfrecs=[]
     
      // this.RefreshFRS(id-1);
    }

  }

  DownloadFileS3()
  {
    //console.log(this.asset)
    window.open('https://s3-us-west-2.amazonaws.com/roc.sgs.report/Informes/'+this.asset.nombre+'.pdf', "_blank");


  }

  ngOnInit(): void {}
  

}
