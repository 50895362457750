import { Component, Input, Output,EventEmitter,OnInit} from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label,Color } from 'ng2-charts';
import {map} from "rxjs/operators";
import { exception } from 'console'; 

@Component({
  selector: 'app-chartbar',
  templateUrl: './chartbar.component.html',
  styles: [
  ]
})
export class ChartbarComponent implements OnInit {
  
  @Input() database:any={datachart:[1,2,3,4]};
  
  @Input() sensores;
  ready=false;
  @Input() datachart:any[]=[];
  @Input() dataranges:any[];
  @Input() 
  set histonchange(val:boolean)
  {
    this._histonchange=val;
    this.next(1);
  };
  private _histonchange;
  contador=-1;
  Accel="Ax";

constructor()
{
  
  

}
ngOnInit()
{
  console.log("DataHistos",this.database)

  this.next(0)

}

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{scaleLabel: {display: true, labelString: 'Rango (umbral=1)'}}], yAxes: [{scaleLabel: {display: true, labelString: '%'}}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  convertRanges(element,fixed,multiplier){
    var temp=element.split(',');
    var temp1= parseFloat(temp[0])/multiplier;
    var temp2= parseFloat(temp[1])/multiplier;
    var n=`${temp1.toFixed(fixed).toString()}-${temp2.toFixed(fixed).toString()}`;
    if(temp1.toFixed(fixed).toString()==temp2.toFixed(fixed).toString())
    {
        n=`${temp2.toFixed(fixed).toString()}<`
    }
    //console.log(element);
    return n;
  }
  next(value) 
  {
    //console.log("Data", this.database.datachart[0])
    var multiplier=1;
    var fixed=2;
    //console.log(this.database)
   
    //console.warn(this.sensores)
      //console.log(this.database.dataranges)
    try
    {
      //console.log(res)
      var res = this.database.dataranges.map(element => {return this.convertRanges(element,fixed,multiplier)});
      this.barChartLabels = res;
      this.barChartData =[this.database.datachart[0][value]]//,this.database.datachart[1][value]];
    }
    catch
    {

    }
     
    

  }
  
  barChartLabels=[];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  //public barChartPlugins = [pluginDataLabels];

  barChartData=[{data:[],label:""}];

//   ButtonClicked(value:number[],value2:Label[])
//   {
// this.barChartLabels=value2;
// this.barChartData=[
//   { data: value, label: 'Acceleration Groups in mg' },
// ];

  //}

  
  public BarChartColors: Color[] = [
    { // celeste
      backgroundColor: 'rgba(150,150,200,0.5)',
      borderColor: 'rgba(200,200,200,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
     { // azul
       backgroundColor: 'rgba(70,70,120,0.5)',
       borderColor: 'rgba(80,80,80,1)',
       pointBackgroundColor: 'rgba(77,83,96,1)',
       pointBorderColor: '#fff',
       pointHoverBackgroundColor: '#fff',
       pointHoverBorderColor: 'rgba(77,83,96,1)'
     },
    
  ];




  

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public randomize(): void {
    // Only Change 3 values
    const data = [
      Math.round(Math.random() * 100),
      59,
      80,
      (Math.random() * 100),
      56,
      (Math.random() * 100),
      40];
    this.barChartData[0].data = data;
  }


 
}
