
<div class="chart-wrapper" *ngIf="Loaded">
  <canvas baseChart 
  [datasets]="barChartData"
  [labels]="barChartLabels"
  [options]="barChartOptions"
  [colors]="BarChartColors"
  [legend]="barChartLegend"
  [chartType]="barChartType"
  (chartHover)="chartHovered($event)"
  (chartClick)="chartClicked($event)"
  
  >

  
</canvas>
</div>

