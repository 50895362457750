import { Component, OnInit ,Input} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/_services';
import { interval } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  
})
export class NavbarComponent implements OnInit {

  @Input() estructura;
  @Input() asset;
  @Input() selected;
  constructor(
    private router:Router,
    private authenticationService: AuthenticationService) { }
    private http: HttpClient
  ngOnInit(): void {
    this.authenticationService.refreshToken()
    interval(1000 * 60 * 2).subscribe(x => {
      this.authenticationService.refreshToken()
    });

  }

  buscarAsset(termino:string)
  {
    this.router.navigate(['/Search',termino])
  }

  cerrarSesion()
  {
    this.authenticationService.logout()
    this.router.navigate(["/login"]);

  }


}
