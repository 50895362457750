import { Component, Input, Output,EventEmitter,OnInit} from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label,Color } from 'ng2-charts';
import {map} from "rxjs/operators"; 
import { exception } from 'console';

@Component({
  selector: 'app-histo-events',
  templateUrl: './histo-events.component.html',
  styleUrls: ['./histo-events.component.css']
})


export class HistoEventsComponent {

  @Input() barChartLabels: Label[] = ['1', '2', '3', '4', '5'];
  @Input() Loaded=false;
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];

  @Input() barChartData: ChartDataSets[] = [
    { data: [45, 37, 60, 70, 46], label: 'Eventos Semanales' }
  ];


  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{scaleLabel: {display: true, labelString: 'Semanas'}}], yAxes: [{scaleLabel: {display: true, labelString: 'Eventos'}}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public BarChartColors: Color[] = [
    { // celeste
      backgroundColor: 'rgba(150,150,200,0.5)',
      borderColor: 'rgba(200,200,200,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
     { // azul
       backgroundColor: 'rgba(70,70,120,0.5)',
       borderColor: 'rgba(80,80,80,1)',
       pointBackgroundColor: 'rgba(77,83,96,1)',
       pointBorderColor: '#fff',
       pointHoverBackgroundColor: '#fff',
       pointHoverBorderColor: 'rgba(77,83,96,1)'
     },
    
  ];

}