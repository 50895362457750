

<div style="max-height: 40vh;">
    <img style="max-width: 753px; max-height: 342px; min-height:  342px; min-width: 753px;" src="{{imgsource}}" usemap="#image-map">

    <map name="image-map" style="">
        <area *ngFor="let item of mapcoords;let i =index"  alt="" title={{mapcoords[i][1]}} href="{{sensorref+'/'+mapcoords[i][1]}}" coords={{mapcoords[i][0]}} shape="circle">
    
    </map>

</div>





