import { Component, OnInit ,Input,Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-dropdown2',
  templateUrl: './dropdown2.component.html',
  styleUrls: ['./dropdown2.component.css']
})
export class Dropdown2Component implements OnInit {
  @Input() sensores;
  @Input() database;
  selectedIDplus=true
  selectedIDminus=false
  selectedID=0
  selected="Seleccione Variable";
  constructor() { 
    
  }

@Output() eventclicked:EventEmitter<any>=new EventEmitter<any>();
  ngOnInit() {
    console.log("DBdd",this.sensores)
  }

  next(i)
  {
    
    this.selectedID+=i
    this.eventclicked.emit(this.selectedID)
    this.selected=this.sensores[this.selectedID]
    console.log(i)
    if(this.selectedID==0)
    {
      this.selectedIDminus=false
    }
    else if(this.selectedID==this.sensores.length-1)
    {
      this.selectedIDplus=false
    }
    else
    {

this.selectedIDminus=true
this.selectedIDplus=true
    }
  }
}
