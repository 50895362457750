import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-videocam',
  templateUrl: './videocam.component.html',
  styleUrls: ['./videocam.component.css']
})
export class VideocamComponent implements OnInit {

  constructor() { }
  @Input() imgsource="./assets/images/camarainactiva.png"
  ngOnInit() {
  }

}
