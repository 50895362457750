<div style="display: block ;">
    <canvas baseChart
      [datasets]="data"
      [options]="radarChartOptions"
      [colors]="BarChartColors"
      [labels]="radarChartLabels"
      [chartType]="radarChartType"
      >
    </canvas>
</div>
